<script setup>
/* Imports */
import {
  computed,
  reactive,
} from 'vue';

/* Analytics */
import {
  sendAnalyticsEvent,
  sanitizeAnalyticsString,
} from '@garmin/analytics';

/* Helpers */
import { constructAnalyticsCatSubcat } from '../helpers/analyticsHelper';
import Services from '../services';
/* Components */
import GalleryDisplay from '../components/GalleryDisplay.vue';

/* Props */
const props = defineProps({
  dev: {
    type: Boolean,
    default: false,
  },
  displayFormat: {
    type: String,
    default: 'carousel',
  },
  enabled: {
    type: Boolean,
    default: true,
  },
  heading: {
    type: String,
    default: undefined,
  },
  locale: {
    type: String,
    default: 'en-US',
  },
  productId: {
    type: String,
    default: undefined,
  },
  categoryKey: {
    type: String,
    default: undefined,
  },
  showUgcMetadata: {
    type: Boolean,
    default: true,
  },
});

const state = reactive({
  customerId: null,
  configEnabled: false,
  galleryImagesLoaded: false,
  media: [],
  streamId: null,
  translations: undefined,
  galleryEnabled: computed(() => state.configEnabled && props.enabled),
});

const setTealiumData = (eventType, direction) => {
  const analyticsData = window?.globalAnalyticsData;

  if (analyticsData) {
    // eslint-disable-next-line camelcase
    const { page_type } = window.globalAnalyticsData;

    // Common properties
    const commonProperties = {
      tealium_event: 'ugc_select_content',
      link_type: 'button',
      event_action: eventType ?? '',
      event_label: `${eventType} ${direction}`,
    };

    // Product Page
    // eslint-disable-next-line camelcase
    if (page_type === 'product_page') {
      const productName = sanitizeAnalyticsString(analyticsData?.productName) || '';
      const productVariation = sanitizeAnalyticsString(analyticsData?.productVariation) || '';

      const productPageProperties = {
        page_name: `${productName} Product Page`,
        product_id: analyticsData?.id ?? '',
        product_name: productName,
        product_variant: productVariation,
        event_category: constructAnalyticsCatSubcat(analyticsData?.pageCategory_global, analyticsData?.categoryName_global), // marketCategoryGlobal >> productCategoryGlobal
        product_price: analyticsData?.price ?? '',
        product_sku: analyticsData?.skuCode ?? '',
        discount: analyticsData?.discount ?? '',
        currency: analyticsData?.priceCurrency ?? '',
        product_category: analyticsData?.pageCategory_global ?? '', // marketCategoryGlobal
        product_subcategory: analyticsData?.categoryName_global ?? '', // productCategoryGlobal
      };

      sendAnalyticsEvent({
        ...commonProperties,
        ...productPageProperties,
      });
    }

    // Category Page
    // eslint-disable-next-line camelcase
    if (page_type === 'category_page') {
      const categoryPageProperties = {
        page_name: constructAnalyticsCatSubcat(analyticsData?.page_category, analyticsData?.page_subcategory),
        page_type: 'subcat',
        event_category: constructAnalyticsCatSubcat(analyticsData?.page_category, analyticsData?.page_subcategory),
        product_category: analyticsData?.page_category ?? '',
        product_subcategory: analyticsData?.page_subcategory ?? '',
      };

      sendAnalyticsEvent({
        ...commonProperties,
        ...categoryPageProperties,
      });
    }
  }
};

/* Events */
const onPreviousClick = () => {
  setTealiumData('Click', 'Left');
};

const onNextClick = () => {
  setTealiumData('Click', 'Right');
};

const onSwipe = (direction) => {
  setTealiumData('Swipe', direction);
};

const initialize = () => {
  Services.getSettings({ locale: props.locale, dev: props.dev }).then((data) => {
    state.configEnabled = data.enabled;
    state.translations = {
      nextButtonLabel: data.nextButtonLabel,
      previousButtonLabel: data.previousButtonLabel,
      navLabel: data.navLabel,
    };

    if (state.galleryEnabled && props.productId) {
      Services.getStreamMedia({ productId: props.productId })
        .then((response) => {
          state.media = response;
        })
        .catch((error) => {
          console.warn(error);
        });
    }

    if (state.galleryEnabled && props.categoryKey) {
      Services.getCategoryByKey({ categoryKey: props.categoryKey })
        .then((response) => {
          // Get recent media url for category
          const recentMediaUrl = response?.data?._embedded['media:recent']?._links?.self?.href;
          return recentMediaUrl;
        })
        .then((recentMediaUrl) => {
          // Use recent media url for category key to get category media
          Services.getCategoryMedia({ mediaUrl: recentMediaUrl })
            .then((response) => {
              state.media = response;
            })
            .catch((error) => {
              console.warn(error);
            });
        })
        .catch((error) => {
          console.warn(error);
        });
    }
  });
};

initialize();
</script>

<template>
  <GalleryDisplay
    :display-format="props.displayFormat"
    :enabled="state.galleryEnabled"
    :heading="props.heading"
    :media="state.media"
    :show-ugc-metadata="props.showUgcMetadata"
    :translations="state.translations"
    @on-previous-click="onPreviousClick()"
    @on-next-click="onNextClick()"
    @on-swipe="(direction) => onSwipe(direction)"
  />
</template>
