<script setup>
/* Props */
const props = defineProps({
  navLabel: {
    type: String,
    default: '',
  },
  previousBtnLabel: {
    type: String,
    default: '',
  },
  previousBtnDisabled: {
    type: Boolean,
    default: true,
  },
  nextBtnLabel: {
    type: String,
    default: '',
  },
});

/* Define emits */
const emit = defineEmits(['onPreviousClick', 'onNextClick']);

/* Events */
const onPreviousClick = () => {
  emit('onPreviousClick');
};

const onNextClick = () => {
  emit('onNextClick');
};
</script>

<template>
  <nav
    class="ugc-gallery__nav"
    data-testid="ugc-gallery-nav"
    :aria-label="props.navLabel"
  >
    <button
      id="ugc-gallery__previous"
      class="ugc-gallery__nav__previous"
      data-testid="ugc-gallery-nav-previous"
      :aria-label="props.previousBtnLabel"
      :disabled="props.previousBtnDisabled"
      @click="onPreviousClick"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 11.2 18.1"
        aria-hidden="true"
        focusable="false"
      >
        <path d="M11.2 9l-9.1 9.1L0 16l6.9-7L0 2.1 2.1 0l9.1 9z" />
      </svg>
    </button>
    <button
      id="ugc-gallery__next"
      class="ugc-gallery__nav__next"
      data-testid="ugc-gallery-nav-next"
      :aria-label="props.nextBtnLabel"
      @click="onNextClick"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 11.2 18.1"
        aria-hidden="true"
        focusable="false"
      >
        <path d="M11.2 9l-9.1 9.1L0 16l6.9-7L0 2.1 2.1 0l9.1 9z" />
      </svg>
    </button>
  </nav>
</template>

<style lang="scss" scoped>
.ugc-gallery__nav {
  position: absolute;
  inset: 0 0 0 0;

  &__next,
  &__previous {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 100;
    width: 56px;
    height: 56px;
    background: $color-white;
    border: none;
    border-radius: 100%;
    box-shadow: rgba(0, 0, 0, 0.25) 0 6px 6px;

    &:hover {
      cursor: pointer;
      box-shadow: rgba(0, 0, 0, 0.5) 0 6px 6px;
    }

    &:disabled {
      opacity: 0;
      cursor: auto;
    }

    svg {
      position: absolute;
      left: 50%;
      transform: translateX(-45%);
      width: 11px;

      path {
        fill: $color-black;
      }
    }
  }

  &__previous {
    left: 1.5rem;

    svg {
      transform: translateX(-55%) rotate(180deg);
    }
  }

  &__next {
    right: 1.5rem;
  }
}
</style>
