<script setup>
/* Imports */
import { computed } from 'vue';

/* Components */
import Gallery from './containers/Gallery.vue';
import GalleryDisplay from './components/GalleryDisplay.vue';

/* Helpers */
import { getStreamMediaMock } from './services/mocks';

/* Computed */
const fourImages = computed(() => getStreamMediaMock.slice(0, 4));
const fiveImages = computed(() => getStreamMediaMock.slice(0, 5));
</script>

<template>
  <div id="app">
    <div id="product-id">
      <h1>Product Id</h1>
      <Gallery
        heading="My custom heading ™"
        product-id="873008"
        locale="en-US"
      />
    </div>
    <div id="category-key">
      <h1>Category Key</h1>
      <Gallery
        heading="My custom heading with special characters fēnix®"
        category-key="FENIX 7 SERIES"
        locale="en-US"
      />
    </div>
    <div id="product-id-and-category-key">
      <h1>Both Product Id and Category Key Set</h1>
      <p>Images should match with the Category Key example above and not the Product Id example</p>
      <Gallery
        heading="My custom heading"
        product-id="873008"
        category-key="Running"
        locale="en-US"
      />
    </div>
    <div id="metadata-hidden">
      <h1>Metadata Hidden</h1>
      <Gallery
        heading="My custom heading"
        product-id="873008"
        locale="en-US"
        :show-ugc-metadata="false"
      />
    </div>
    <div id="no-heading">
      <h1>No heading</h1>
      <Gallery
        product-id="873008"
        locale="en-US"
      />
    </div>
    <div id="dev-settings">
      <h1>Dev Settings</h1>
      <Gallery
        product-id="873008"
        locale="en-US"
        :dev="true"
      />
    </div>
    <div id="five-images">
      <h1>Five Images</h1>
      <GalleryDisplay
        :media="fiveImages"
        :enabled="true"
      />
    </div>
    <div id="four-images">
      <h1>Four Images</h1>
      <p>Should not display gallery</p>
      <GalleryDisplay
        :media="fourImages"
        :enabled="true"
      />
    </div>
    <div id="grid">
      <h1>Grid Images</h1>
      <Gallery
        product-id="873008"
        locale="en-US"
        display-format="grid"
      />
    </div>
  </div>
</template>
