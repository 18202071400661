/* eslint-disable max-len */
export const getStreamMediaResponseMock = {
  headers: {
    server: 'nginx',
    'content-type': 'application/json',
    vary: 'Accept-Encoding',
    'access-control-allow-origin': '*',
    etag: 'W/"29b0b1558064287a240148a75ebdf137"',
    'content-encoding': 'gzip',
    'content-length': '5701',
    'cache-control': 'public, max-age=1729',
    date: 'Mon, 29 Aug 2022 16:48:37 GMT',
    connection: 'keep-alive',
  },
  url: 'https://photorankapi-a.akamaihd.net/streams/media/recent?tag_key=641449&version=v2.2&auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&rights_given=1&include_tagged_galleries=0',
  method: null,
  httpVersion: '1.1',
  statusCode: 200,
  statusMessage: 'OK',
  body: {
    metadata: {
      code: 200,
      message: 'OK',
      version: 'v2.0',
    },
    data: {
      _links: {
        first: {
          href: '//photorankapi-a.akamaihd.net/streams/2223355598/media/recent?page_key=c68d0f0319d9e6b19f5103c7e61e623a&page_number=1&auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
        },
        self: {
          href: '//photorankapi-a.akamaihd.net/streams/2223355598/media/recent?page_key=c68d0f0319d9e6b19f5103c7e61e623a&page_number=1&auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
        },
        prev: {
          href: null,
        },
        next: {
          href: null,
        },
      },
      _embedded: {
        media: [
          {
            _links: {
              self: {
                href: '//photorankapi-a.akamaihd.net/media/4026961317?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
              },
            },
            id: '4026961317',
            _fixed: true,
            type: 'IMAGE',
            source: 'instagram_graph',
            source_id: null,
            original_source: 'https://www.instagram.com/p/Cg7XCknJ4Yj/',
            caption:
              'I had a fun time running out on the streets of San Diego near Balboa Park at the Balboa Park 8 miler this morning. After years of being out of town for this race, we’ve now run it 2 years in a row.\n\nNot my best race & not my worst. I had an exhausting week & it was pretty warm out there, at times I felt like my head/face was on fire I was so hot but I finished & got to enjoy a beautiful San Diego morning with lots of like minded people and ultimately that’s what I’m looking for right now 😊.\n\nAnd, I had two women come out of the blue at two different places & say that I looked great today, which was a nice surprise & a little confidence boost. Lol\n\nHappy Saturday friends - have a fabulous weekend!\n#runinrabbit #radrabbit #radresults #sandiego #balboapark #finishline #balboapark8miler #racebling #rungoodr #garminfitness #garminoutdoor #runner #sandiegorunning #youlookgreat',
            video_url: null,
            share_url: 'https://buy.garmin.com/en-US/US/p/641449#opi4026961317',
            date_submitted: '2022-08-06T18:53:34+00:00',
            date_published: '2022-08-23T22:01:57+00:00',
            favorite: false,
            location: null,
            sonar_place: null,
            original_image_width: '1440',
            original_image_height: '1800',
            status: 'approved',
            likes: 47,
            request_id: null,
            images: {
              square:
                'https://photorankmedia-a.akamaihd.net/media/u/g/6/ug6ow55/square.jpg',
              thumbnail:
                'https://z1photorankmedia-a.akamaihd.net/media/u/g/6/ug6ow55/thumbnail.jpg',
              mobile:
                'https://z3photorankmedia-a.akamaihd.net/media/u/g/6/ug6ow55/mobile.jpg',
              normal:
                'https://z3photorankmedia-a.akamaihd.net/media/u/g/6/ug6ow55/normal.jpg',
              original:
                'https://z3photorankmedia-a.akamaihd.net/media/u/g/6/ug6ow55/original.jpg',
            },
            _embedded: {
              uploader: {
                _links: {
                  self: {
                    href: '//photorankapi-a.akamaihd.net/users/151003649?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                  },
                },
                id: '151003649',
                _fixed: true,
                name: 'runnylegs',
                avatar_url:
                  'https://photorankmedia-a.akamaihd.net/resources/avatar-48.png',
                language: null,
                username: 'runnylegs',
                social_connections: {
                  instagram_graph: {
                    username: 'runnylegs',
                    source_id: '4ed58893c4556de9ea7b390d9483d85c',
                  },
                },
                _embedded: {
                  'media:recent': {
                    _links: {
                      self: {
                        href: '//photorankapi-a.akamaihd.net/users/151003649/media/recent?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                      },
                    },
                  },
                },
                _forms: {
                  'media:upload': {
                    title: 'Upload media',
                    action: {
                      href: '//photorankapi-a.akamaihd.net/users/151003649/media',
                    },
                    method: 'POST',
                    fields: [
                      {
                        type: 'text',
                        prompt: 'Caption',
                        name: 'caption',
                        value: '',
                        placeholder: '',
                      },
                      {
                        type: 'text',
                        prompt: 'URL',
                        name: 'url',
                        value: '',
                        placeholder: '',
                      },
                    ],
                  },
                },
              },
              'streams:all': {
                _fixed: true,
                _links: {
                  self: {
                    href: '//photorankapi-a.akamaihd.net/media/4026961317/streams?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                  },
                },
                _embedded: {
                  stream: [
                    {
                      _links: {
                        self: {
                          href: '//photorankapi-a.akamaihd.net/streams/2223355598?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                        },
                      },
                      id: '2223355598',
                      _fixed: true,
                      shop_button_url:
                        '//photorank.me/widget/redirect_shop_this_product?data=eyJjdXN0b21lcl9pZCI6IjIyMDc0MiIsIm1lZGlhX2lkIjoiNDAyNjk2MTMxNyIsInNob3BfYnV0dG9uX3VybCI6Imh0dHBzOlwvXC9idXkuZ2FybWluLmNvbVwvZW4tVVNcL1VTXC9wXC82NDE0NDkifQ%3D%3D',
                      share_url:
                        'https://buy.garmin.com/en-US/US/p/641449#opi4026961317',
                      _embedded: {
                        customer: {
                          _links: {
                            self: {
                              href: '//photorankapi-a.akamaihd.net/customers/220742?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                            },
                          },
                          id: '220742',
                          _fixed: false,
                        },
                        base_image: {
                          _links: {
                            self: {
                              href: '//photorankapi-a.akamaihd.net/media/3965303268?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                            },
                          },
                          id: '3965303268',
                          _fixed: true,
                          type: 'IMAGE',
                          source: 'base_image',
                          source_id: null,
                          original_source: null,
                          caption: 'fēnix® 6',
                          video_url: null,
                          share_url: null,
                          date_submitted: '2022-04-05T14:30:41+00:00',
                          date_published: null,
                          favorite: false,
                          location: null,
                          sonar_place: null,
                          original_image_width: '600',
                          original_image_height: '600',
                          status: null,
                          likes: 0,
                          request_id: null,
                          images: {
                            square:
                              'https://z2photorankmedia-a.akamaihd.net/media/5/i/f/5ifoc45/square.jpg',
                            thumbnail:
                              'https://photorankmedia-a.akamaihd.net/media/5/i/f/5ifoc45/thumbnail.jpg',
                            mobile:
                              'https://z3photorankmedia-a.akamaihd.net/media/5/i/f/5ifoc45/mobile.jpg',
                            normal:
                              'https://z3photorankmedia-a.akamaihd.net/media/5/i/f/5ifoc45/normal.jpg',
                            original:
                              'https://z1photorankmedia-a.akamaihd.net/media/5/i/f/5ifoc45/original.jpeg',
                          },
                          _embedded: {
                            uploader: null,
                            'streams:all': null,
                            'categories:all': null,
                          },
                          _forms: null,
                          _analytics: {
                            oid: '3965303268',
                            t: 'media',
                            meta: ['user_agent', 'event_type', 'is_mobile'],
                          },
                        },
                        cover_media: null,
                        'media:recent': {
                          _links: {
                            self: {
                              href: '//photorankapi-a.akamaihd.net/streams/2223355598/media/recent?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                            },
                          },
                        },
                        'media:shuffled': {
                          _links: {
                            self: {
                              href: '//photorankapi-a.akamaihd.net/streams/2223355598/media/shuffled?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                            },
                          },
                        },
                        'media:photorank': {
                          _links: {
                            self: {
                              href: '//photorankapi-a.akamaihd.net/streams/2223355598/media/photorank?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                            },
                          },
                        },
                        'media:rated': {
                          _links: {
                            self: {
                              href: '//photorankapi-a.akamaihd.net/streams/2223355598/media/rated?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                            },
                          },
                        },
                        'media:media_position': {
                          _links: {
                            self: {
                              href: '//photorankapi-a.akamaihd.net/streams/2223355598/media/media_position?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                            },
                          },
                        },
                      },
                      name: 'fēnix® 6',
                      description:
                        'Combining the best features of our fitness and outdoor watches, fēnix 6 is the multisport GPS watch that brings cutting-edge design and performance to your fast-paced lifestyle..Keep an eye on your heart with wrist-based heart rate and Pulse Ox..Stretch battery life to the limit — go up to 14 days between charges in smartwatch mode..Get more running and cycling stats than you know what to do with..The world is yours to explore. Navigation sensors help you find your way back..No matter where you go, text messages and alerts will always be able to find you..No more fumbling for cash. Just use your watch to pay on the go .',
                      tag_based_key: '641449',
                      product_url: 'https://buy.garmin.com/en-US/US/p/641449',
                      hide_from_related: false,
                      product_info: {
                        price: null,
                        availability: null,
                        stock: null,
                        color: null,
                      },
                      status: 'available',
                      _analytics: {
                        oid: '2223355598',
                        t: 'gallery',
                        meta: ['user_agent', 'event_type', 'is_mobile'],
                      },
                    },
                  ],
                },
              },
              'categories:all': {
                _fixed: true,
                _links: {
                  self: {
                    href: '//photorankapi-a.akamaihd.net/media/4026961317/categories?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                  },
                },
                _embedded: {
                  category: null,
                },
              },
            },
            _forms: {
              report: {
                title: 'Report photo?',
                action: {
                  href: '//photorankapi-a.akamaihd.net/media/4026961317/reports',
                },
                method: 'POST',
                fields: [
                  {
                    type: 'email',
                    prompt: 'Email',
                    name: 'email',
                    value: '',
                    placeholder: 'my@email.com',
                  },
                  {
                    type: 'short-text',
                    prompt: 'Reason',
                    name: 'reason',
                    value: '',
                    placeholder: 'Reason',
                  },
                  {
                    type: 'submit',
                    prompt: '',
                    name: 'send',
                    value: 'Report',
                    placeholder: '',
                  },
                ],
              },
            },
            _analytics: {
              oid: '4026961317',
              t: 'media',
              meta: ['user_agent', 'event_type', 'is_mobile'],
            },
          },
          {
            _links: {
              self: {
                href: '//photorankapi-a.akamaihd.net/media/3984486269?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
              },
            },
            id: '3984486269',
            _fixed: true,
            type: 'IMAGE',
            source: 'instagram_graph',
            source_id: null,
            original_source: 'https://www.instagram.com/p/Cc6qg1tpKmA/',
            caption:
              'This year has not been easy for me, but I think I finally have turned a corner. Here’s to taking changes & doing what’s best for you. 💙\n#sandiego #downtown #sandiegoharbor #harborview #cityscape #mentalhealth #doyou #runinrabbit #garminoutdoor',
            video_url: null,
            share_url: 'https://buy.garmin.com/en-US/US/p/641449#opi3984486269',
            date_submitted: '2022-04-29T01:42:25+00:00',
            date_published: '2022-08-23T22:01:56+00:00',
            favorite: false,
            location: null,
            sonar_place: null,
            original_image_width: '1440',
            original_image_height: '1800',
            status: 'approved',
            likes: 0,
            request_id: null,
            images: {
              square:
                'https://photorankmedia-a.akamaihd.net/media/7/r/8/7r8ut45/square.jpg',
              thumbnail:
                'https://z3photorankmedia-a.akamaihd.net/media/7/r/8/7r8ut45/thumbnail.jpg',
              mobile:
                'https://z1photorankmedia-a.akamaihd.net/media/7/r/8/7r8ut45/mobile.jpg',
              normal:
                'https://z1photorankmedia-a.akamaihd.net/media/7/r/8/7r8ut45/normal.jpg',
              original:
                'https://z1photorankmedia-a.akamaihd.net/media/7/r/8/7r8ut45/original.jpg',
            },
            _embedded: {
              uploader: {
                _links: {
                  self: {
                    href: '//photorankapi-a.akamaihd.net/users/151003649?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                  },
                },
                id: '151003649',
                _fixed: true,
                name: 'runnylegs',
                avatar_url:
                  'https://photorankmedia-a.akamaihd.net/resources/avatar-48.png',
                language: null,
                username: 'runnylegs',
                social_connections: {
                  instagram_graph: {
                    username: 'runnylegs',
                    source_id: '4ed58893c4556de9ea7b390d9483d85c',
                  },
                },
                _embedded: {
                  'media:recent': {
                    _links: {
                      self: {
                        href: '//photorankapi-a.akamaihd.net/users/151003649/media/recent?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                      },
                    },
                  },
                },
                _forms: {
                  'media:upload': {
                    title: 'Upload media',
                    action: {
                      href: '//photorankapi-a.akamaihd.net/users/151003649/media',
                    },
                    method: 'POST',
                    fields: [
                      {
                        type: 'text',
                        prompt: 'Caption',
                        name: 'caption',
                        value: '',
                        placeholder: '',
                      },
                      {
                        type: 'text',
                        prompt: 'URL',
                        name: 'url',
                        value: '',
                        placeholder: '',
                      },
                    ],
                  },
                },
              },
              'streams:all': {
                _fixed: true,
                _links: {
                  self: {
                    href: '//photorankapi-a.akamaihd.net/media/3984486269/streams?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                  },
                },
                _embedded: {
                  stream: [
                    {
                      _links: {
                        self: {
                          href: '//photorankapi-a.akamaihd.net/streams/2223355598?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                        },
                      },
                      id: '2223355598',
                      _fixed: true,
                      shop_button_url:
                        '//photorank.me/widget/redirect_shop_this_product?data=eyJjdXN0b21lcl9pZCI6IjIyMDc0MiIsIm1lZGlhX2lkIjoiMzk4NDQ4NjI2OSIsInNob3BfYnV0dG9uX3VybCI6Imh0dHBzOlwvXC9idXkuZ2FybWluLmNvbVwvZW4tVVNcL1VTXC9wXC82NDE0NDkifQ%3D%3D',
                      share_url:
                        'https://buy.garmin.com/en-US/US/p/641449#opi3984486269',
                      _embedded: {
                        customer: {
                          _links: {
                            self: {
                              href: '//photorankapi-a.akamaihd.net/customers/220742?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                            },
                          },
                          id: '220742',
                          _fixed: false,
                        },
                        base_image: {
                          _links: {
                            self: {
                              href: '//photorankapi-a.akamaihd.net/media/3965303268?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                            },
                          },
                          id: '3965303268',
                          _fixed: true,
                          type: 'IMAGE',
                          source: 'base_image',
                          source_id: null,
                          original_source: null,
                          caption: 'fēnix® 6',
                          video_url: null,
                          share_url: null,
                          date_submitted: '2022-04-05T14:30:41+00:00',
                          date_published: null,
                          favorite: false,
                          location: null,
                          sonar_place: null,
                          original_image_width: '600',
                          original_image_height: '600',
                          status: null,
                          likes: 0,
                          request_id: null,
                          images: {
                            square:
                              'https://z2photorankmedia-a.akamaihd.net/media/5/i/f/5ifoc45/square.jpg',
                            thumbnail:
                              'https://photorankmedia-a.akamaihd.net/media/5/i/f/5ifoc45/thumbnail.jpg',
                            mobile:
                              'https://photorankmedia-a.akamaihd.net/media/5/i/f/5ifoc45/mobile.jpg',
                            normal:
                              'https://z1photorankmedia-a.akamaihd.net/media/5/i/f/5ifoc45/normal.jpg',
                            original:
                              'https://z1photorankmedia-a.akamaihd.net/media/5/i/f/5ifoc45/original.jpeg',
                          },
                          _embedded: {
                            uploader: null,
                            'streams:all': null,
                            'categories:all': null,
                          },
                          _forms: null,
                          _analytics: {
                            oid: '3965303268',
                            t: 'media',
                            meta: ['user_agent', 'event_type', 'is_mobile'],
                          },
                        },
                        cover_media: null,
                        'media:recent': {
                          _links: {
                            self: {
                              href: '//photorankapi-a.akamaihd.net/streams/2223355598/media/recent?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                            },
                          },
                        },
                        'media:shuffled': {
                          _links: {
                            self: {
                              href: '//photorankapi-a.akamaihd.net/streams/2223355598/media/shuffled?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                            },
                          },
                        },
                        'media:photorank': {
                          _links: {
                            self: {
                              href: '//photorankapi-a.akamaihd.net/streams/2223355598/media/photorank?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                            },
                          },
                        },
                        'media:rated': {
                          _links: {
                            self: {
                              href: '//photorankapi-a.akamaihd.net/streams/2223355598/media/rated?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                            },
                          },
                        },
                        'media:media_position': {
                          _links: {
                            self: {
                              href: '//photorankapi-a.akamaihd.net/streams/2223355598/media/media_position?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                            },
                          },
                        },
                      },
                      name: 'fēnix® 6',
                      description:
                        'Combining the best features of our fitness and outdoor watches, fēnix 6 is the multisport GPS watch that brings cutting-edge design and performance to your fast-paced lifestyle..Keep an eye on your heart with wrist-based heart rate and Pulse Ox..Stretch battery life to the limit — go up to 14 days between charges in smartwatch mode..Get more running and cycling stats than you know what to do with..The world is yours to explore. Navigation sensors help you find your way back..No matter where you go, text messages and alerts will always be able to find you..No more fumbling for cash. Just use your watch to pay on the go .',
                      tag_based_key: '641449',
                      product_url: 'https://buy.garmin.com/en-US/US/p/641449',
                      hide_from_related: false,
                      product_info: {
                        price: null,
                        availability: null,
                        stock: null,
                        color: null,
                      },
                      status: 'available',
                      _analytics: {
                        oid: '2223355598',
                        t: 'gallery',
                        meta: ['user_agent', 'event_type', 'is_mobile'],
                      },
                    },
                  ],
                },
              },
              'categories:all': {
                _fixed: true,
                _links: {
                  self: {
                    href: '//photorankapi-a.akamaihd.net/media/3984486269/categories?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                  },
                },
                _embedded: {
                  category: null,
                },
              },
            },
            _forms: {
              report: {
                title: 'Report photo?',
                action: {
                  href: '//photorankapi-a.akamaihd.net/media/3984486269/reports',
                },
                method: 'POST',
                fields: [
                  {
                    type: 'email',
                    prompt: 'Email',
                    name: 'email',
                    value: '',
                    placeholder: 'my@email.com',
                  },
                  {
                    type: 'short-text',
                    prompt: 'Reason',
                    name: 'reason',
                    value: '',
                    placeholder: 'Reason',
                  },
                  {
                    type: 'submit',
                    prompt: '',
                    name: 'send',
                    value: 'Report',
                    placeholder: '',
                  },
                ],
              },
            },
            _analytics: {
              oid: '3984486269',
              t: 'media',
              meta: ['user_agent', 'event_type', 'is_mobile'],
            },
          },
          {
            _links: {
              self: {
                href: '//photorankapi-a.akamaihd.net/media/3957269376?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
              },
            },
            id: '3957269376',
            _fixed: true,
            type: 'IMAGE',
            source: 'instagram_graph',
            source_id: null,
            original_source: 'https://www.instagram.com/p/CbL87GxOd9b/',
            caption:
              'garminfishhunt Fenix 6 was made for the outdoors! We are unbelievably blown away by this watch. Head over and pick yourself one up.',
            video_url: null,
            share_url: 'https://buy.garmin.com/en-US/US/p/641449#opi3957269376',
            date_submitted: '2022-03-17T01:37:11+00:00',
            date_published: '2022-03-28T19:11:34+00:00',
            favorite: false,
            location: null,
            sonar_place: null,
            original_image_width: '1440',
            original_image_height: '1799',
            status: 'approved',
            likes: 0,
            request_id: null,
            images: {
              square:
                'https://z3photorankmedia-a.akamaihd.net/media/u/7/v/u7vv545/square.jpg',
              thumbnail:
                'https://z1photorankmedia-a.akamaihd.net/media/u/7/v/u7vv545/thumbnail.jpg',
              mobile:
                'https://photorankmedia-a.akamaihd.net/media/u/7/v/u7vv545/mobile.jpg',
              normal:
                'https://z3photorankmedia-a.akamaihd.net/media/u/7/v/u7vv545/normal.jpg',
              original:
                'https://z3photorankmedia-a.akamaihd.net/media/u/7/v/u7vv545/original.jpg',
            },
            _embedded: {
              uploader: {
                _links: {
                  self: {
                    href: '//photorankapi-a.akamaihd.net/users/187841102?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                  },
                },
                id: '187841102',
                _fixed: true,
                name: 'hollowridgeoutdoors',
                avatar_url:
                  'https://photorankmedia-a.akamaihd.net/resources/avatar-48.png',
                language: null,
                username: 'hollowridgeoutdoors',
                social_connections: {
                  instagram_graph: {
                    username: 'hollowridgeoutdoors',
                    source_id: '9c28dfd2e2533f5c225db0235213e114',
                  },
                },
                _embedded: {
                  'media:recent': {
                    _links: {
                      self: {
                        href: '//photorankapi-a.akamaihd.net/users/187841102/media/recent?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                      },
                    },
                  },
                },
                _forms: {
                  'media:upload': {
                    title: 'Upload media',
                    action: {
                      href: '//photorankapi-a.akamaihd.net/users/187841102/media',
                    },
                    method: 'POST',
                    fields: [
                      {
                        type: 'text',
                        prompt: 'Caption',
                        name: 'caption',
                        value: '',
                        placeholder: '',
                      },
                      {
                        type: 'text',
                        prompt: 'URL',
                        name: 'url',
                        value: '',
                        placeholder: '',
                      },
                    ],
                  },
                },
              },
              'streams:all': {
                _fixed: true,
                _links: {
                  self: {
                    href: '//photorankapi-a.akamaihd.net/media/3957269376/streams?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                  },
                },
                _embedded: {
                  stream: [
                    {
                      _links: {
                        self: {
                          href: '//photorankapi-a.akamaihd.net/streams/2223355598?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                        },
                      },
                      id: '2223355598',
                      _fixed: true,
                      shop_button_url:
                        '//photorank.me/widget/redirect_shop_this_product?data=eyJjdXN0b21lcl9pZCI6IjIyMDc0MiIsIm1lZGlhX2lkIjoiMzk1NzI2OTM3NiIsInNob3BfYnV0dG9uX3VybCI6Imh0dHBzOlwvXC9idXkuZ2FybWluLmNvbVwvZW4tVVNcL1VTXC9wXC82NDE0NDkifQ%3D%3D',
                      share_url:
                        'https://buy.garmin.com/en-US/US/p/641449#opi3957269376',
                      _embedded: {
                        customer: {
                          _links: {
                            self: {
                              href: '//photorankapi-a.akamaihd.net/customers/220742?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                            },
                          },
                          id: '220742',
                          _fixed: false,
                        },
                        base_image: {
                          _links: {
                            self: {
                              href: '//photorankapi-a.akamaihd.net/media/3965303268?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                            },
                          },
                          id: '3965303268',
                          _fixed: true,
                          type: 'IMAGE',
                          source: 'base_image',
                          source_id: null,
                          original_source: null,
                          caption: 'fēnix® 6',
                          video_url: null,
                          share_url: null,
                          date_submitted: '2022-04-05T14:30:41+00:00',
                          date_published: null,
                          favorite: false,
                          location: null,
                          sonar_place: null,
                          original_image_width: '600',
                          original_image_height: '600',
                          status: null,
                          likes: 0,
                          request_id: null,
                          images: {
                            square:
                              'https://photorankmedia-a.akamaihd.net/media/5/i/f/5ifoc45/square.jpg',
                            thumbnail:
                              'https://z1photorankmedia-a.akamaihd.net/media/5/i/f/5ifoc45/thumbnail.jpg',
                            mobile:
                              'https://photorankmedia-a.akamaihd.net/media/5/i/f/5ifoc45/mobile.jpg',
                            normal:
                              'https://z3photorankmedia-a.akamaihd.net/media/5/i/f/5ifoc45/normal.jpg',
                            original:
                              'https://z3photorankmedia-a.akamaihd.net/media/5/i/f/5ifoc45/original.jpeg',
                          },
                          _embedded: {
                            uploader: null,
                            'streams:all': null,
                            'categories:all': null,
                          },
                          _forms: null,
                          _analytics: {
                            oid: '3965303268',
                            t: 'media',
                            meta: ['user_agent', 'event_type', 'is_mobile'],
                          },
                        },
                        cover_media: null,
                        'media:recent': {
                          _links: {
                            self: {
                              href: '//photorankapi-a.akamaihd.net/streams/2223355598/media/recent?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                            },
                          },
                        },
                        'media:shuffled': {
                          _links: {
                            self: {
                              href: '//photorankapi-a.akamaihd.net/streams/2223355598/media/shuffled?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                            },
                          },
                        },
                        'media:photorank': {
                          _links: {
                            self: {
                              href: '//photorankapi-a.akamaihd.net/streams/2223355598/media/photorank?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                            },
                          },
                        },
                        'media:rated': {
                          _links: {
                            self: {
                              href: '//photorankapi-a.akamaihd.net/streams/2223355598/media/rated?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                            },
                          },
                        },
                        'media:media_position': {
                          _links: {
                            self: {
                              href: '//photorankapi-a.akamaihd.net/streams/2223355598/media/media_position?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                            },
                          },
                        },
                      },
                      name: 'fēnix® 6',
                      description:
                        'Combining the best features of our fitness and outdoor watches, fēnix 6 is the multisport GPS watch that brings cutting-edge design and performance to your fast-paced lifestyle..Keep an eye on your heart with wrist-based heart rate and Pulse Ox..Stretch battery life to the limit — go up to 14 days between charges in smartwatch mode..Get more running and cycling stats than you know what to do with..The world is yours to explore. Navigation sensors help you find your way back..No matter where you go, text messages and alerts will always be able to find you..No more fumbling for cash. Just use your watch to pay on the go .',
                      tag_based_key: '641449',
                      product_url: 'https://buy.garmin.com/en-US/US/p/641449',
                      hide_from_related: false,
                      product_info: {
                        price: null,
                        availability: null,
                        stock: null,
                        color: null,
                      },
                      status: 'available',
                      _analytics: {
                        oid: '2223355598',
                        t: 'gallery',
                        meta: ['user_agent', 'event_type', 'is_mobile'],
                      },
                    },
                  ],
                },
              },
              'categories:all': {
                _fixed: true,
                _links: {
                  self: {
                    href: '//photorankapi-a.akamaihd.net/media/3957269376/categories?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                  },
                },
                _embedded: {
                  category: null,
                },
              },
            },
            _forms: {
              report: {
                title: 'Report photo?',
                action: {
                  href: '//photorankapi-a.akamaihd.net/media/3957269376/reports',
                },
                method: 'POST',
                fields: [
                  {
                    type: 'email',
                    prompt: 'Email',
                    name: 'email',
                    value: '',
                    placeholder: 'my@email.com',
                  },
                  {
                    type: 'short-text',
                    prompt: 'Reason',
                    name: 'reason',
                    value: '',
                    placeholder: 'Reason',
                  },
                  {
                    type: 'submit',
                    prompt: '',
                    name: 'send',
                    value: 'Report',
                    placeholder: '',
                  },
                ],
              },
            },
            _analytics: {
              oid: '3957269376',
              t: 'media',
              meta: ['user_agent', 'event_type', 'is_mobile'],
            },
          },
          {
            _links: {
              self: {
                href: '//photorankapi-a.akamaihd.net/media/3943101107?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
              },
            },
            id: '3943101107',
            _fixed: true,
            type: 'IMAGE',
            source: 'instagram_graph',
            source_id: null,
            original_source: 'https://www.instagram.com/p/CZecsrFISNx/',
            caption:
              'Devastated! Aiming for a 50min 10k by February just missed out by 18 seconds!!! Just have to work harder this month! #garmin #garminrunning #garminconnect #garminfenix6 #garminfitness #runnersofinstagram #runner #runners #runnersworld #runnerslife #runnerscommunity #10km #10kmrun #10kmrunning #roadrunning #roadrunners #gettingfit #gettingfitter #weightloss #weightlossjourney #weightlosstransformation garminfitness garminoutdoor garmin garminuk',
            video_url: null,
            share_url: 'https://buy.garmin.com/en-US/US/p/641449#opi3943101107',
            date_submitted: '2022-02-02T12:58:27+00:00',
            date_published: '2022-03-14T19:44:01+00:00',
            favorite: false,
            location: null,
            sonar_place: null,
            original_image_width: '1440',
            original_image_height: '1800',
            status: 'approved',
            likes: 0,
            request_id: null,
            images: {
              square:
                'https://photorankmedia-a.akamaihd.net/media/d/u/m/dumxr35/square.jpg',
              thumbnail:
                'https://z2photorankmedia-a.akamaihd.net/media/d/u/m/dumxr35/thumbnail.jpg',
              mobile:
                'https://z3photorankmedia-a.akamaihd.net/media/d/u/m/dumxr35/mobile.jpg',
              normal:
                'https://z1photorankmedia-a.akamaihd.net/media/d/u/m/dumxr35/normal.jpg',
              original:
                'https://z2photorankmedia-a.akamaihd.net/media/d/u/m/dumxr35/original.jpg',
            },
            _embedded: {
              uploader: {
                _links: {
                  self: {
                    href: '//photorankapi-a.akamaihd.net/users/183389492?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                  },
                },
                id: '183389492',
                _fixed: true,
                name: 'willgriff818',
                avatar_url:
                  'https://photorankmedia-a.akamaihd.net/resources/avatar-48.png',
                language: null,
                username: 'willgriff818',
                social_connections: {
                  instagram_graph: {
                    username: 'willgriff818',
                    source_id: '66f609127f8ac46a27b598cd605916ae',
                  },
                },
                _embedded: {
                  'media:recent': {
                    _links: {
                      self: {
                        href: '//photorankapi-a.akamaihd.net/users/183389492/media/recent?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                      },
                    },
                  },
                },
                _forms: {
                  'media:upload': {
                    title: 'Upload media',
                    action: {
                      href: '//photorankapi-a.akamaihd.net/users/183389492/media',
                    },
                    method: 'POST',
                    fields: [
                      {
                        type: 'text',
                        prompt: 'Caption',
                        name: 'caption',
                        value: '',
                        placeholder: '',
                      },
                      {
                        type: 'text',
                        prompt: 'URL',
                        name: 'url',
                        value: '',
                        placeholder: '',
                      },
                    ],
                  },
                },
              },
              'streams:all': {
                _fixed: true,
                _links: {
                  self: {
                    href: '//photorankapi-a.akamaihd.net/media/3943101107/streams?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                  },
                },
                _embedded: {
                  stream: [
                    {
                      _links: {
                        self: {
                          href: '//photorankapi-a.akamaihd.net/streams/2223355598?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                        },
                      },
                      id: '2223355598',
                      _fixed: true,
                      shop_button_url:
                        '//photorank.me/widget/redirect_shop_this_product?data=eyJjdXN0b21lcl9pZCI6IjIyMDc0MiIsIm1lZGlhX2lkIjoiMzk0MzEwMTEwNyIsInNob3BfYnV0dG9uX3VybCI6Imh0dHBzOlwvXC9idXkuZ2FybWluLmNvbVwvZW4tVVNcL1VTXC9wXC82NDE0NDkifQ%3D%3D',
                      share_url:
                        'https://buy.garmin.com/en-US/US/p/641449#opi3943101107',
                      _embedded: {
                        customer: {
                          _links: {
                            self: {
                              href: '//photorankapi-a.akamaihd.net/customers/220742?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                            },
                          },
                          id: '220742',
                          _fixed: false,
                        },
                        base_image: {
                          _links: {
                            self: {
                              href: '//photorankapi-a.akamaihd.net/media/3965303268?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                            },
                          },
                          id: '3965303268',
                          _fixed: true,
                          type: 'IMAGE',
                          source: 'base_image',
                          source_id: null,
                          original_source: null,
                          caption: 'fēnix® 6',
                          video_url: null,
                          share_url: null,
                          date_submitted: '2022-04-05T14:30:41+00:00',
                          date_published: null,
                          favorite: false,
                          location: null,
                          sonar_place: null,
                          original_image_width: '600',
                          original_image_height: '600',
                          status: null,
                          likes: 0,
                          request_id: null,
                          images: {
                            square:
                              'https://z2photorankmedia-a.akamaihd.net/media/5/i/f/5ifoc45/square.jpg',
                            thumbnail:
                              'https://z3photorankmedia-a.akamaihd.net/media/5/i/f/5ifoc45/thumbnail.jpg',
                            mobile:
                              'https://z2photorankmedia-a.akamaihd.net/media/5/i/f/5ifoc45/mobile.jpg',
                            normal:
                              'https://z3photorankmedia-a.akamaihd.net/media/5/i/f/5ifoc45/normal.jpg',
                            original:
                              'https://z2photorankmedia-a.akamaihd.net/media/5/i/f/5ifoc45/original.jpeg',
                          },
                          _embedded: {
                            uploader: null,
                            'streams:all': null,
                            'categories:all': null,
                          },
                          _forms: null,
                          _analytics: {
                            oid: '3965303268',
                            t: 'media',
                            meta: ['user_agent', 'event_type', 'is_mobile'],
                          },
                        },
                        cover_media: null,
                        'media:recent': {
                          _links: {
                            self: {
                              href: '//photorankapi-a.akamaihd.net/streams/2223355598/media/recent?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                            },
                          },
                        },
                        'media:shuffled': {
                          _links: {
                            self: {
                              href: '//photorankapi-a.akamaihd.net/streams/2223355598/media/shuffled?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                            },
                          },
                        },
                        'media:photorank': {
                          _links: {
                            self: {
                              href: '//photorankapi-a.akamaihd.net/streams/2223355598/media/photorank?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                            },
                          },
                        },
                        'media:rated': {
                          _links: {
                            self: {
                              href: '//photorankapi-a.akamaihd.net/streams/2223355598/media/rated?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                            },
                          },
                        },
                        'media:media_position': {
                          _links: {
                            self: {
                              href: '//photorankapi-a.akamaihd.net/streams/2223355598/media/media_position?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                            },
                          },
                        },
                      },
                      name: 'fēnix® 6',
                      description:
                        'Combining the best features of our fitness and outdoor watches, fēnix 6 is the multisport GPS watch that brings cutting-edge design and performance to your fast-paced lifestyle..Keep an eye on your heart with wrist-based heart rate and Pulse Ox..Stretch battery life to the limit — go up to 14 days between charges in smartwatch mode..Get more running and cycling stats than you know what to do with..The world is yours to explore. Navigation sensors help you find your way back..No matter where you go, text messages and alerts will always be able to find you..No more fumbling for cash. Just use your watch to pay on the go .',
                      tag_based_key: '641449',
                      product_url: 'https://buy.garmin.com/en-US/US/p/641449',
                      hide_from_related: false,
                      product_info: {
                        price: null,
                        availability: null,
                        stock: null,
                        color: null,
                      },
                      status: 'available',
                      _analytics: {
                        oid: '2223355598',
                        t: 'gallery',
                        meta: ['user_agent', 'event_type', 'is_mobile'],
                      },
                    },
                  ],
                },
              },
              'categories:all': {
                _fixed: true,
                _links: {
                  self: {
                    href: '//photorankapi-a.akamaihd.net/media/3943101107/categories?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                  },
                },
                _embedded: {
                  category: null,
                },
              },
            },
            _forms: {
              report: {
                title: 'Report photo?',
                action: {
                  href: '//photorankapi-a.akamaihd.net/media/3943101107/reports',
                },
                method: 'POST',
                fields: [
                  {
                    type: 'email',
                    prompt: 'Email',
                    name: 'email',
                    value: '',
                    placeholder: 'my@email.com',
                  },
                  {
                    type: 'short-text',
                    prompt: 'Reason',
                    name: 'reason',
                    value: '',
                    placeholder: 'Reason',
                  },
                  {
                    type: 'submit',
                    prompt: '',
                    name: 'send',
                    value: 'Report',
                    placeholder: '',
                  },
                ],
              },
            },
            _analytics: {
              oid: '3943101107',
              t: 'media',
              meta: ['user_agent', 'event_type', 'is_mobile'],
            },
          },
          {
            _links: {
              self: {
                href: '//photorankapi-a.akamaihd.net/media/3949534045?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
              },
            },
            id: '3949534045',
            _fixed: true,
            type: 'IMAGE',
            source: 'instagram_graph',
            source_id: null,
            original_source: 'https://www.instagram.com/p/CaPYgC7rcCm/',
            caption:
              '52-365:  BE ALL IN\n\nYou can’t ride the fence forever and expect to succeed or expect excellence. Sure you might do well or have some wins but if you want to achieve greatness, you have to BE ALL IN!\n \nWORKOUT - 45 mins ZONE 2\n\n#run #cardio #strength #fitness #conditioning #mobility #endurance #hypridathlete #tattoos #tattooedmilitary #military #USAF #lftvet #keepmoving #newbalance #newbalancerunning #garmin #garminfenix6',
            video_url: null,
            share_url: 'https://buy.garmin.com/en-US/US/p/641449#opi3949534045',
            date_submitted: '2022-02-21T13:15:31+00:00',
            date_published: '2022-03-14T19:30:03+00:00',
            favorite: false,
            location: null,
            sonar_place: null,
            original_image_width: '1440',
            original_image_height: '1800',
            status: 'approved',
            likes: 2,
            request_id: null,
            images: {
              square:
                'https://z3photorankmedia-a.akamaihd.net/media/v/2/n/v2ndx35/square.jpg',
              thumbnail:
                'https://photorankmedia-a.akamaihd.net/media/v/2/n/v2ndx35/thumbnail.jpg',
              mobile:
                'https://z3photorankmedia-a.akamaihd.net/media/v/2/n/v2ndx35/mobile.jpg',
              normal:
                'https://z2photorankmedia-a.akamaihd.net/media/v/2/n/v2ndx35/normal.jpg',
              original:
                'https://z3photorankmedia-a.akamaihd.net/media/v/2/n/v2ndx35/original.jpg',
            },
            _embedded: {
              uploader: {
                _links: {
                  self: {
                    href: '//photorankapi-a.akamaihd.net/users/186946289?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                  },
                },
                id: '186946289',
                _fixed: true,
                name: '_wilderwarren',
                avatar_url:
                  'https://photorankmedia-a.akamaihd.net/resources/avatar-48.png',
                language: null,
                username: '_wilderwarren',
                social_connections: {
                  instagram_graph: {
                    username: '_wilderwarren',
                    source_id: '9d1a56878386d338f906cec34a57f520',
                  },
                },
                _embedded: {
                  'media:recent': {
                    _links: {
                      self: {
                        href: '//photorankapi-a.akamaihd.net/users/186946289/media/recent?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                      },
                    },
                  },
                },
                _forms: {
                  'media:upload': {
                    title: 'Upload media',
                    action: {
                      href: '//photorankapi-a.akamaihd.net/users/186946289/media',
                    },
                    method: 'POST',
                    fields: [
                      {
                        type: 'text',
                        prompt: 'Caption',
                        name: 'caption',
                        value: '',
                        placeholder: '',
                      },
                      {
                        type: 'text',
                        prompt: 'URL',
                        name: 'url',
                        value: '',
                        placeholder: '',
                      },
                    ],
                  },
                },
              },
              'streams:all': {
                _fixed: true,
                _links: {
                  self: {
                    href: '//photorankapi-a.akamaihd.net/media/3949534045/streams?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                  },
                },
                _embedded: {
                  stream: [
                    {
                      _links: {
                        self: {
                          href: '//photorankapi-a.akamaihd.net/streams/2223355598?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                        },
                      },
                      id: '2223355598',
                      _fixed: true,
                      shop_button_url:
                        '//photorank.me/widget/redirect_shop_this_product?data=eyJjdXN0b21lcl9pZCI6IjIyMDc0MiIsIm1lZGlhX2lkIjoiMzk0OTUzNDA0NSIsInNob3BfYnV0dG9uX3VybCI6Imh0dHBzOlwvXC9idXkuZ2FybWluLmNvbVwvZW4tVVNcL1VTXC9wXC82NDE0NDkifQ%3D%3D',
                      share_url:
                        'https://buy.garmin.com/en-US/US/p/641449#opi3949534045',
                      _embedded: {
                        customer: {
                          _links: {
                            self: {
                              href: '//photorankapi-a.akamaihd.net/customers/220742?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                            },
                          },
                          id: '220742',
                          _fixed: false,
                        },
                        base_image: {
                          _links: {
                            self: {
                              href: '//photorankapi-a.akamaihd.net/media/3965303268?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                            },
                          },
                          id: '3965303268',
                          _fixed: true,
                          type: 'IMAGE',
                          source: 'base_image',
                          source_id: null,
                          original_source: null,
                          caption: 'fēnix® 6',
                          video_url: null,
                          share_url: null,
                          date_submitted: '2022-04-05T14:30:41+00:00',
                          date_published: null,
                          favorite: false,
                          location: null,
                          sonar_place: null,
                          original_image_width: '600',
                          original_image_height: '600',
                          status: null,
                          likes: 0,
                          request_id: null,
                          images: {
                            square:
                              'https://z2photorankmedia-a.akamaihd.net/media/5/i/f/5ifoc45/square.jpg',
                            thumbnail:
                              'https://photorankmedia-a.akamaihd.net/media/5/i/f/5ifoc45/thumbnail.jpg',
                            mobile:
                              'https://z1photorankmedia-a.akamaihd.net/media/5/i/f/5ifoc45/mobile.jpg',
                            normal:
                              'https://z1photorankmedia-a.akamaihd.net/media/5/i/f/5ifoc45/normal.jpg',
                            original:
                              'https://photorankmedia-a.akamaihd.net/media/5/i/f/5ifoc45/original.jpeg',
                          },
                          _embedded: {
                            uploader: null,
                            'streams:all': null,
                            'categories:all': null,
                          },
                          _forms: null,
                          _analytics: {
                            oid: '3965303268',
                            t: 'media',
                            meta: ['user_agent', 'event_type', 'is_mobile'],
                          },
                        },
                        cover_media: null,
                        'media:recent': {
                          _links: {
                            self: {
                              href: '//photorankapi-a.akamaihd.net/streams/2223355598/media/recent?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                            },
                          },
                        },
                        'media:shuffled': {
                          _links: {
                            self: {
                              href: '//photorankapi-a.akamaihd.net/streams/2223355598/media/shuffled?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                            },
                          },
                        },
                        'media:photorank': {
                          _links: {
                            self: {
                              href: '//photorankapi-a.akamaihd.net/streams/2223355598/media/photorank?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                            },
                          },
                        },
                        'media:rated': {
                          _links: {
                            self: {
                              href: '//photorankapi-a.akamaihd.net/streams/2223355598/media/rated?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                            },
                          },
                        },
                        'media:media_position': {
                          _links: {
                            self: {
                              href: '//photorankapi-a.akamaihd.net/streams/2223355598/media/media_position?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                            },
                          },
                        },
                      },
                      name: 'fēnix® 6',
                      description:
                        'Combining the best features of our fitness and outdoor watches, fēnix 6 is the multisport GPS watch that brings cutting-edge design and performance to your fast-paced lifestyle..Keep an eye on your heart with wrist-based heart rate and Pulse Ox..Stretch battery life to the limit — go up to 14 days between charges in smartwatch mode..Get more running and cycling stats than you know what to do with..The world is yours to explore. Navigation sensors help you find your way back..No matter where you go, text messages and alerts will always be able to find you..No more fumbling for cash. Just use your watch to pay on the go .',
                      tag_based_key: '641449',
                      product_url: 'https://buy.garmin.com/en-US/US/p/641449',
                      hide_from_related: false,
                      product_info: {
                        price: null,
                        availability: null,
                        stock: null,
                        color: null,
                      },
                      status: 'available',
                      _analytics: {
                        oid: '2223355598',
                        t: 'gallery',
                        meta: ['user_agent', 'event_type', 'is_mobile'],
                      },
                    },
                  ],
                },
              },
              'categories:all': {
                _fixed: true,
                _links: {
                  self: {
                    href: '//photorankapi-a.akamaihd.net/media/3949534045/categories?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                  },
                },
                _embedded: {
                  category: null,
                },
              },
            },
            _forms: {
              report: {
                title: 'Report photo?',
                action: {
                  href: '//photorankapi-a.akamaihd.net/media/3949534045/reports',
                },
                method: 'POST',
                fields: [
                  {
                    type: 'email',
                    prompt: 'Email',
                    name: 'email',
                    value: '',
                    placeholder: 'my@email.com',
                  },
                  {
                    type: 'short-text',
                    prompt: 'Reason',
                    name: 'reason',
                    value: '',
                    placeholder: 'Reason',
                  },
                  {
                    type: 'submit',
                    prompt: '',
                    name: 'send',
                    value: 'Report',
                    placeholder: '',
                  },
                ],
              },
            },
            _analytics: {
              oid: '3949534045',
              t: 'media',
              meta: ['user_agent', 'event_type', 'is_mobile'],
            },
          },
          {
            _links: {
              self: {
                href: '//photorankapi-a.akamaihd.net/media/3953956866?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
              },
            },
            id: '3953956866',
            _fixed: true,
            type: 'IMAGE',
            source: 'instagram_graph',
            source_id: null,
            original_source: 'https://www.instagram.com/p/Cayc_kcOa1T/',
            caption:
              'My Garmin shows  no mercy after a full marathon today, tells me to move. :) gage_l.o.g garminfitness  hahaha',
            video_url: null,
            share_url: 'https://buy.garmin.com/en-US/US/p/641449#opi3953956866',
            date_submitted: '2022-03-07T03:57:23+00:00',
            date_published: '2022-03-08T21:47:01+00:00',
            favorite: false,
            location: null,
            sonar_place: null,
            original_image_width: '1440',
            original_image_height: '1440',
            status: 'approved',
            likes: 0,
            request_id: null,
            images: {
              square:
                'https://photorankmedia-a.akamaihd.net/media/r/e/p/rep4345/square.jpg',
              thumbnail:
                'https://z2photorankmedia-a.akamaihd.net/media/r/e/p/rep4345/thumbnail.jpg',
              mobile:
                'https://photorankmedia-a.akamaihd.net/media/r/e/p/rep4345/mobile.jpg',
              normal:
                'https://z2photorankmedia-a.akamaihd.net/media/r/e/p/rep4345/normal.jpg',
              original:
                'https://photorankmedia-a.akamaihd.net/media/r/e/p/rep4345/original.webp',
            },
            _embedded: {
              uploader: {
                _links: {
                  self: {
                    href: '//photorankapi-a.akamaihd.net/users/188157986?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                  },
                },
                id: '188157986',
                _fixed: true,
                name: 'pooall76',
                avatar_url:
                  'https://photorankmedia-a.akamaihd.net/resources/avatar-48.png',
                language: null,
                username: 'pooall76',
                social_connections: {
                  instagram_graph: {
                    username: 'pooall76',
                    source_id: 'b6406d106fca6dccfd3f55b105817993',
                  },
                },
                _embedded: {
                  'media:recent': {
                    _links: {
                      self: {
                        href: '//photorankapi-a.akamaihd.net/users/188157986/media/recent?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                      },
                    },
                  },
                },
                _forms: {
                  'media:upload': {
                    title: 'Upload media',
                    action: {
                      href: '//photorankapi-a.akamaihd.net/users/188157986/media',
                    },
                    method: 'POST',
                    fields: [
                      {
                        type: 'text',
                        prompt: 'Caption',
                        name: 'caption',
                        value: '',
                        placeholder: '',
                      },
                      {
                        type: 'text',
                        prompt: 'URL',
                        name: 'url',
                        value: '',
                        placeholder: '',
                      },
                    ],
                  },
                },
              },
              'streams:all': {
                _fixed: true,
                _links: {
                  self: {
                    href: '//photorankapi-a.akamaihd.net/media/3953956866/streams?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                  },
                },
                _embedded: {
                  stream: [
                    {
                      _links: {
                        self: {
                          href: '//photorankapi-a.akamaihd.net/streams/2223355598?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                        },
                      },
                      id: '2223355598',
                      _fixed: true,
                      shop_button_url:
                        '//photorank.me/widget/redirect_shop_this_product?data=eyJjdXN0b21lcl9pZCI6IjIyMDc0MiIsIm1lZGlhX2lkIjoiMzk1Mzk1Njg2NiIsInNob3BfYnV0dG9uX3VybCI6Imh0dHBzOlwvXC9idXkuZ2FybWluLmNvbVwvZW4tVVNcL1VTXC9wXC82NDE0NDkifQ%3D%3D',
                      share_url:
                        'https://buy.garmin.com/en-US/US/p/641449#opi3953956866',
                      _embedded: {
                        customer: {
                          _links: {
                            self: {
                              href: '//photorankapi-a.akamaihd.net/customers/220742?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                            },
                          },
                          id: '220742',
                          _fixed: false,
                        },
                        base_image: {
                          _links: {
                            self: {
                              href: '//photorankapi-a.akamaihd.net/media/3965303268?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                            },
                          },
                          id: '3965303268',
                          _fixed: true,
                          type: 'IMAGE',
                          source: 'base_image',
                          source_id: null,
                          original_source: null,
                          caption: 'fēnix® 6',
                          video_url: null,
                          share_url: null,
                          date_submitted: '2022-04-05T14:30:41+00:00',
                          date_published: null,
                          favorite: false,
                          location: null,
                          sonar_place: null,
                          original_image_width: '600',
                          original_image_height: '600',
                          status: null,
                          likes: 0,
                          request_id: null,
                          images: {
                            square:
                              'https://z3photorankmedia-a.akamaihd.net/media/5/i/f/5ifoc45/square.jpg',
                            thumbnail:
                              'https://photorankmedia-a.akamaihd.net/media/5/i/f/5ifoc45/thumbnail.jpg',
                            mobile:
                              'https://photorankmedia-a.akamaihd.net/media/5/i/f/5ifoc45/mobile.jpg',
                            normal:
                              'https://z2photorankmedia-a.akamaihd.net/media/5/i/f/5ifoc45/normal.jpg',
                            original:
                              'https://z3photorankmedia-a.akamaihd.net/media/5/i/f/5ifoc45/original.jpeg',
                          },
                          _embedded: {
                            uploader: null,
                            'streams:all': null,
                            'categories:all': null,
                          },
                          _forms: null,
                          _analytics: {
                            oid: '3965303268',
                            t: 'media',
                            meta: ['user_agent', 'event_type', 'is_mobile'],
                          },
                        },
                        cover_media: null,
                        'media:recent': {
                          _links: {
                            self: {
                              href: '//photorankapi-a.akamaihd.net/streams/2223355598/media/recent?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                            },
                          },
                        },
                        'media:shuffled': {
                          _links: {
                            self: {
                              href: '//photorankapi-a.akamaihd.net/streams/2223355598/media/shuffled?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                            },
                          },
                        },
                        'media:photorank': {
                          _links: {
                            self: {
                              href: '//photorankapi-a.akamaihd.net/streams/2223355598/media/photorank?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                            },
                          },
                        },
                        'media:rated': {
                          _links: {
                            self: {
                              href: '//photorankapi-a.akamaihd.net/streams/2223355598/media/rated?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                            },
                          },
                        },
                        'media:media_position': {
                          _links: {
                            self: {
                              href: '//photorankapi-a.akamaihd.net/streams/2223355598/media/media_position?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                            },
                          },
                        },
                      },
                      name: 'fēnix® 6',
                      description:
                        'Combining the best features of our fitness and outdoor watches, fēnix 6 is the multisport GPS watch that brings cutting-edge design and performance to your fast-paced lifestyle..Keep an eye on your heart with wrist-based heart rate and Pulse Ox..Stretch battery life to the limit — go up to 14 days between charges in smartwatch mode..Get more running and cycling stats than you know what to do with..The world is yours to explore. Navigation sensors help you find your way back..No matter where you go, text messages and alerts will always be able to find you..No more fumbling for cash. Just use your watch to pay on the go .',
                      tag_based_key: '641449',
                      product_url: 'https://buy.garmin.com/en-US/US/p/641449',
                      hide_from_related: false,
                      product_info: {
                        price: null,
                        availability: null,
                        stock: null,
                        color: null,
                      },
                      status: 'available',
                      _analytics: {
                        oid: '2223355598',
                        t: 'gallery',
                        meta: ['user_agent', 'event_type', 'is_mobile'],
                      },
                    },
                  ],
                },
              },
              'categories:all': {
                _fixed: true,
                _links: {
                  self: {
                    href: '//photorankapi-a.akamaihd.net/media/3953956866/categories?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                  },
                },
                _embedded: {
                  category: null,
                },
              },
            },
            _forms: {
              report: {
                title: 'Report photo?',
                action: {
                  href: '//photorankapi-a.akamaihd.net/media/3953956866/reports',
                },
                method: 'POST',
                fields: [
                  {
                    type: 'email',
                    prompt: 'Email',
                    name: 'email',
                    value: '',
                    placeholder: 'my@email.com',
                  },
                  {
                    type: 'short-text',
                    prompt: 'Reason',
                    name: 'reason',
                    value: '',
                    placeholder: 'Reason',
                  },
                  {
                    type: 'submit',
                    prompt: '',
                    name: 'send',
                    value: 'Report',
                    placeholder: '',
                  },
                ],
              },
            },
            _analytics: {
              oid: '3953956866',
              t: 'media',
              meta: ['user_agent', 'event_type', 'is_mobile'],
            },
          },
          {
            _links: {
              self: {
                href: '//photorankapi-a.akamaihd.net/media/3954076642?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
              },
            },
            id: '3954076642',
            _fixed: true,
            type: 'IMAGE',
            source: 'instagram_graph',
            source_id: null,
            original_source: 'https://www.instagram.com/p/Cay9Ln2tEF3/',
            caption:
              'Medal monday! Last sunday I ran my first half marathon of this year and started with a big PR of 1.41.03! More than 9 minutes of my old time on this distance.\n\nAlso my first race focused only my wattage with strydrunning No heartrate, no pace, just wattage. What joy to run with just one metric that adapts immediately to changes in pace, wind, uphill & down!',
            video_url: null,
            share_url: 'https://buy.garmin.com/en-US/US/p/641449#opi3954076642',
            date_submitted: '2022-03-07T08:43:13+00:00',
            date_published: '2022-03-08T21:32:02+00:00',
            favorite: false,
            location: null,
            sonar_place: null,
            original_image_width: '1440',
            original_image_height: '1800',
            status: 'approved',
            likes: 1,
            request_id: null,
            images: {
              square:
                'https://z3photorankmedia-a.akamaihd.net/media/c/e/2/ce28345/square.jpg',
              thumbnail:
                'https://z1photorankmedia-a.akamaihd.net/media/c/e/2/ce28345/thumbnail.jpg',
              mobile:
                'https://z1photorankmedia-a.akamaihd.net/media/c/e/2/ce28345/mobile.jpg',
              normal:
                'https://z3photorankmedia-a.akamaihd.net/media/c/e/2/ce28345/normal.jpg',
              original:
                'https://z3photorankmedia-a.akamaihd.net/media/c/e/2/ce28345/original.jpg',
            },
            _embedded: {
              uploader: {
                _links: {
                  self: {
                    href: '//photorankapi-a.akamaihd.net/users/186211108?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                  },
                },
                id: '186211108',
                _fixed: true,
                name: 'bgjvanrossum',
                avatar_url:
                  'https://photorankmedia-a.akamaihd.net/resources/avatar-48.png',
                language: null,
                username: 'bgjvanrossum',
                social_connections: {
                  instagram_graph: {
                    username: 'bgjvanrossum',
                    source_id: '7454bb3939da9a097d8213b7b838c7aa',
                  },
                },
                _embedded: {
                  'media:recent': {
                    _links: {
                      self: {
                        href: '//photorankapi-a.akamaihd.net/users/186211108/media/recent?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                      },
                    },
                  },
                },
                _forms: {
                  'media:upload': {
                    title: 'Upload media',
                    action: {
                      href: '//photorankapi-a.akamaihd.net/users/186211108/media',
                    },
                    method: 'POST',
                    fields: [
                      {
                        type: 'text',
                        prompt: 'Caption',
                        name: 'caption',
                        value: '',
                        placeholder: '',
                      },
                      {
                        type: 'text',
                        prompt: 'URL',
                        name: 'url',
                        value: '',
                        placeholder: '',
                      },
                    ],
                  },
                },
              },
              'streams:all': {
                _fixed: true,
                _links: {
                  self: {
                    href: '//photorankapi-a.akamaihd.net/media/3954076642/streams?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                  },
                },
                _embedded: {
                  stream: [
                    {
                      _links: {
                        self: {
                          href: '//photorankapi-a.akamaihd.net/streams/2223355598?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                        },
                      },
                      id: '2223355598',
                      _fixed: true,
                      shop_button_url:
                        '//photorank.me/widget/redirect_shop_this_product?data=eyJjdXN0b21lcl9pZCI6IjIyMDc0MiIsIm1lZGlhX2lkIjoiMzk1NDA3NjY0MiIsInNob3BfYnV0dG9uX3VybCI6Imh0dHBzOlwvXC9idXkuZ2FybWluLmNvbVwvZW4tVVNcL1VTXC9wXC82NDE0NDkifQ%3D%3D',
                      share_url:
                        'https://buy.garmin.com/en-US/US/p/641449#opi3954076642',
                      _embedded: {
                        customer: {
                          _links: {
                            self: {
                              href: '//photorankapi-a.akamaihd.net/customers/220742?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                            },
                          },
                          id: '220742',
                          _fixed: false,
                        },
                        base_image: {
                          _links: {
                            self: {
                              href: '//photorankapi-a.akamaihd.net/media/3965303268?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                            },
                          },
                          id: '3965303268',
                          _fixed: true,
                          type: 'IMAGE',
                          source: 'base_image',
                          source_id: null,
                          original_source: null,
                          caption: 'fēnix® 6',
                          video_url: null,
                          share_url: null,
                          date_submitted: '2022-04-05T14:30:41+00:00',
                          date_published: null,
                          favorite: false,
                          location: null,
                          sonar_place: null,
                          original_image_width: '600',
                          original_image_height: '600',
                          status: null,
                          likes: 0,
                          request_id: null,
                          images: {
                            square:
                              'https://z3photorankmedia-a.akamaihd.net/media/5/i/f/5ifoc45/square.jpg',
                            thumbnail:
                              'https://photorankmedia-a.akamaihd.net/media/5/i/f/5ifoc45/thumbnail.jpg',
                            mobile:
                              'https://z2photorankmedia-a.akamaihd.net/media/5/i/f/5ifoc45/mobile.jpg',
                            normal:
                              'https://z2photorankmedia-a.akamaihd.net/media/5/i/f/5ifoc45/normal.jpg',
                            original:
                              'https://photorankmedia-a.akamaihd.net/media/5/i/f/5ifoc45/original.jpeg',
                          },
                          _embedded: {
                            uploader: null,
                            'streams:all': null,
                            'categories:all': null,
                          },
                          _forms: null,
                          _analytics: {
                            oid: '3965303268',
                            t: 'media',
                            meta: ['user_agent', 'event_type', 'is_mobile'],
                          },
                        },
                        cover_media: null,
                        'media:recent': {
                          _links: {
                            self: {
                              href: '//photorankapi-a.akamaihd.net/streams/2223355598/media/recent?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                            },
                          },
                        },
                        'media:shuffled': {
                          _links: {
                            self: {
                              href: '//photorankapi-a.akamaihd.net/streams/2223355598/media/shuffled?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                            },
                          },
                        },
                        'media:photorank': {
                          _links: {
                            self: {
                              href: '//photorankapi-a.akamaihd.net/streams/2223355598/media/photorank?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                            },
                          },
                        },
                        'media:rated': {
                          _links: {
                            self: {
                              href: '//photorankapi-a.akamaihd.net/streams/2223355598/media/rated?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                            },
                          },
                        },
                        'media:media_position': {
                          _links: {
                            self: {
                              href: '//photorankapi-a.akamaihd.net/streams/2223355598/media/media_position?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                            },
                          },
                        },
                      },
                      name: 'fēnix® 6',
                      description:
                        'Combining the best features of our fitness and outdoor watches, fēnix 6 is the multisport GPS watch that brings cutting-edge design and performance to your fast-paced lifestyle..Keep an eye on your heart with wrist-based heart rate and Pulse Ox..Stretch battery life to the limit — go up to 14 days between charges in smartwatch mode..Get more running and cycling stats than you know what to do with..The world is yours to explore. Navigation sensors help you find your way back..No matter where you go, text messages and alerts will always be able to find you..No more fumbling for cash. Just use your watch to pay on the go .',
                      tag_based_key: '641449',
                      product_url: 'https://buy.garmin.com/en-US/US/p/641449',
                      hide_from_related: false,
                      product_info: {
                        price: null,
                        availability: null,
                        stock: null,
                        color: null,
                      },
                      status: 'available',
                      _analytics: {
                        oid: '2223355598',
                        t: 'gallery',
                        meta: ['user_agent', 'event_type', 'is_mobile'],
                      },
                    },
                  ],
                },
              },
              'categories:all': {
                _fixed: true,
                _links: {
                  self: {
                    href: '//photorankapi-a.akamaihd.net/media/3954076642/categories?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                  },
                },
                _embedded: {
                  category: null,
                },
              },
            },
            _forms: {
              report: {
                title: 'Report photo?',
                action: {
                  href: '//photorankapi-a.akamaihd.net/media/3954076642/reports',
                },
                method: 'POST',
                fields: [
                  {
                    type: 'email',
                    prompt: 'Email',
                    name: 'email',
                    value: '',
                    placeholder: 'my@email.com',
                  },
                  {
                    type: 'short-text',
                    prompt: 'Reason',
                    name: 'reason',
                    value: '',
                    placeholder: 'Reason',
                  },
                  {
                    type: 'submit',
                    prompt: '',
                    name: 'send',
                    value: 'Report',
                    placeholder: '',
                  },
                ],
              },
            },
            _analytics: {
              oid: '3954076642',
              t: 'media',
              meta: ['user_agent', 'event_type', 'is_mobile'],
            },
          },
        ],
        customer: {
          _links: {
            self: {
              href: '//photorankapi-a.akamaihd.net/customers/220742?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
            },
          },
          id: '220742',
          _fixed: true,
          name: 'Garmin',
          domain: null,
          template_dir: 'garmin',
          language: 'en_US',
          settings: {
            force_viewer_modal: true,
            column_number: '3',
            items_per_page: 10,
            uploader_actions: 'listSources,labeling,login,finish',
            show_in_home: false,
            show_in_home_id: '0',
            force_https: false,
            ab_testing: 0,
            olapicU: 'enabled',
            customer_dependant: {
              assets2: 'default',
              uploader: 'uploader1v2',
              viewer: 'viewer2v2',
              widget: 'widget2',
            },
            analytics_cookie_domain: '',
            premoderation: true,
            tagging: true,
            analytics_api_version: 'v2',
            analytics_checkout_file_prefix: '',
            analytics_dashboard_engagement: false,
            analytics_conversion_interval: '30-minutes',
            currency: '$',
            analytics_enterprise_analytics: false,
            currency_info: {
              code: 'USD',
              symbol: '$',
              name: 'United States Dollar',
            },
            analytics_error_tracking: false,
            analytics_ga_integration: false,
            analytics_disabled: false,
          },
          views: {
            viewer:
              '//photorankstatics-a.akamaihd.net/assets/garmin/viewer2v2.html',
            uploader:
              '//photorankstatics-a.akamaihd.net/assets/garmin/uploader1v2.html',
          },
          _embedded: {
            user: {
              _links: {
                self: {
                  href: '//photorankapi-a.akamaihd.net/users/186680800?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                },
              },
              id: '186680800',
              _fixed: false,
            },
            media: {
              _links: {
                self: {
                  href: '//photorankapi-a.akamaihd.net/customers/220742/media/recent?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                },
              },
              _fixed: false,
            },
            'media:recent': {
              _links: {
                self: {
                  href: '//photorankapi-a.akamaihd.net/customers/220742/media/recent?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                },
              },
              _fixed: false,
            },
            'media:shuffled': {
              _links: {
                self: {
                  href: '//photorankapi-a.akamaihd.net/customers/220742/media/shuffled?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                },
              },
              _fixed: false,
            },
            'media:photorank': {
              _links: {
                self: {
                  href: '//photorankapi-a.akamaihd.net/customers/220742/media/photorank?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                },
              },
              _fixed: false,
            },
            'media:rated': {
              _links: {
                self: {
                  href: '//photorankapi-a.akamaihd.net/customers/220742/media/rated?auth_token=cbf029ae0e6637f1f81e3681ed45055d806a37298458a036194a7fe00f4ba8ae&version=v2.2',
                },
              },
              _fixed: false,
            },
          },
          _forms: {
            'streams:search': {
              title: 'Search streams',
              action: {
                href: '//photorankapi-a.akamaihd.net/customers/220742/streams/search',
              },
              method: 'GET',
              fields: [
                {
                  type: 'text',
                  prompt: 'Product ID',
                  name: 'tag_key',
                  value: '',
                  placeholder: '',
                },
              ],
            },
            'categories:search': {
              title: 'Search categories',
              action: {
                href: '//photorankapi-a.akamaihd.net/customers/220742/categories/search',
              },
              method: 'GET',
              fields: [
                {
                  type: 'text',
                  prompt: 'Tag',
                  name: 'tag_key',
                  value: '',
                  placeholder: '',
                },
              ],
            },
            'stashes:create': {
              title: 'Create stash',
              action: {
                href: '//photorankapi-a.akamaihd.net/stashes',
              },
              method: 'POST',
              fields: [
                {
                  type: 'file',
                  prompt: 'File',
                  name: 'file',
                  value: '',
                  placeholder: '',
                },
              ],
            },
            'users:create': {
              title: 'Create user',
              action: {
                href: '//photorankapi-a.akamaihd.net/users',
              },
              method: 'POST',
              fields: [
                {
                  type: 'text',
                  prompt: 'Email',
                  name: 'email',
                  value: '',
                  placeholder: '',
                },
                {
                  type: 'text',
                  prompt: 'Screen name',
                  name: 'screen_name',
                  value: '',
                  placeholder: '',
                },
                {
                  type: 'text',
                  prompt: 'Avatar URL',
                  name: 'avatar_url',
                  value: '',
                  placeholder: '',
                },
              ],
            },
          },
        },
      },
    },
  },
};
/* eslint-enable max-len */

export const getStreamMediaSuccessResponseMock = getStreamMediaResponseMock.body;

export const getStreamMediaMock = [
  {
    images: {
      mobile:
        'https://z3photorankmedia-a.akamaihd.net/media/u/g/6/ug6ow55/mobile.jpg',
      normal:
        'https://z3photorankmedia-a.akamaihd.net/media/u/g/6/ug6ow55/normal.jpg',
      original:
        'https://z3photorankmedia-a.akamaihd.net/media/u/g/6/ug6ow55/original.jpg',
      square:
        'https://photorankmedia-a.akamaihd.net/media/u/g/6/ug6ow55/square.jpg',
      thumbnail:
        'https://z1photorankmedia-a.akamaihd.net/media/u/g/6/ug6ow55/thumbnail.jpg',
    },
    username: 'runnylegs',
  },
  {
    images: {
      mobile:
        'https://z1photorankmedia-a.akamaihd.net/media/7/r/8/7r8ut45/mobile.jpg',
      normal:
        'https://z1photorankmedia-a.akamaihd.net/media/7/r/8/7r8ut45/normal.jpg',
      original:
        'https://z1photorankmedia-a.akamaihd.net/media/7/r/8/7r8ut45/original.jpg',
      square:
        'https://photorankmedia-a.akamaihd.net/media/7/r/8/7r8ut45/square.jpg',
      thumbnail:
        'https://z3photorankmedia-a.akamaihd.net/media/7/r/8/7r8ut45/thumbnail.jpg',
    },
    username: 'runnylegs',
  },
  {
    images: {
      mobile:
        'https://photorankmedia-a.akamaihd.net/media/u/7/v/u7vv545/mobile.jpg',
      normal:
        'https://z3photorankmedia-a.akamaihd.net/media/u/7/v/u7vv545/normal.jpg',
      original:
        'https://z3photorankmedia-a.akamaihd.net/media/u/7/v/u7vv545/original.jpg',
      square:
        'https://z3photorankmedia-a.akamaihd.net/media/u/7/v/u7vv545/square.jpg',
      thumbnail:
        'https://z1photorankmedia-a.akamaihd.net/media/u/7/v/u7vv545/thumbnail.jpg',
    },
    username: 'hollowridgeoutdoors',
  },
  {
    images: {
      mobile:
        'https://z3photorankmedia-a.akamaihd.net/media/d/u/m/dumxr35/mobile.jpg',
      normal:
        'https://z1photorankmedia-a.akamaihd.net/media/d/u/m/dumxr35/normal.jpg',
      original:
        'https://z2photorankmedia-a.akamaihd.net/media/d/u/m/dumxr35/original.jpg',
      square:
        'https://photorankmedia-a.akamaihd.net/media/d/u/m/dumxr35/square.jpg',
      thumbnail:
        'https://z2photorankmedia-a.akamaihd.net/media/d/u/m/dumxr35/thumbnail.jpg',
    },
    username: 'willgriff818',
  },
  {
    images: {
      mobile:
        'https://z3photorankmedia-a.akamaihd.net/media/v/2/n/v2ndx35/mobile.jpg',
      normal:
        'https://z2photorankmedia-a.akamaihd.net/media/v/2/n/v2ndx35/normal.jpg',
      original:
        'https://z3photorankmedia-a.akamaihd.net/media/v/2/n/v2ndx35/original.jpg',
      square:
        'https://z3photorankmedia-a.akamaihd.net/media/v/2/n/v2ndx35/square.jpg',
      thumbnail:
        'https://photorankmedia-a.akamaihd.net/media/v/2/n/v2ndx35/thumbnail.jpg',
    },
    username: '_wilderwarren',
  },
  {
    images: {
      mobile:
        'https://photorankmedia-a.akamaihd.net/media/r/e/p/rep4345/mobile.jpg',
      normal:
        'https://z2photorankmedia-a.akamaihd.net/media/r/e/p/rep4345/normal.jpg',
      original:
        'https://photorankmedia-a.akamaihd.net/media/r/e/p/rep4345/original.webp',
      square:
        'https://photorankmedia-a.akamaihd.net/media/r/e/p/rep4345/square.jpg',
      thumbnail:
        'https://z2photorankmedia-a.akamaihd.net/media/r/e/p/rep4345/thumbnail.jpg',
    },
    username: 'pooall76',
  },
  {
    images: {
      mobile:
        'https://z1photorankmedia-a.akamaihd.net/media/c/e/2/ce28345/mobile.jpg',
      normal:
        'https://z3photorankmedia-a.akamaihd.net/media/c/e/2/ce28345/normal.jpg',
      original:
        'https://z3photorankmedia-a.akamaihd.net/media/c/e/2/ce28345/original.jpg',
      square:
        'https://z3photorankmedia-a.akamaihd.net/media/c/e/2/ce28345/square.jpg',
      thumbnail:
        'https://z1photorankmedia-a.akamaihd.net/media/c/e/2/ce28345/thumbnail.jpg',
    },
    username: 'bgjvanrossum',
  },
];

export const getSettingsMock = {
  identifier: 'ugc_config',
  nameInternal: 'UGC Olapic Config',
  heading: 'Show off your {{HASHTAG}}',
  nextButtonLabel: 'Next',
  previousButtonLabel: 'Previous',
  navLabel: 'Social Slider Navigation',
  enabled: true,
};

export const getTranslationsMock = {
  nextButtonLabel: getSettingsMock.nextButtonLabel,
  previousButtonLabel: getSettingsMock.previousButtonLabel,
  navLabel: getSettingsMock.navLabelButtonLabel,
};
