<script setup>
/* Imports */
import { computed } from 'vue';

/* Components */
import InstagramLogo from './InstagramLogo.vue';

const props = defineProps({
  images: {
    type: Object,
    default: () => {},
  },
  showUgcMetadata: {
    type: Boolean,
    default: true,
  },
  username: {
    type: String,
    default: '',
  },
});

const ugcGalleryImageComputedStyle = computed(() => ({
  backgroundImage: `url(${props.images.normal})`,
}));
</script>

<template>
  <figure
    class="ugc-gallery-image__figure"
  >
    <div
      class="ugc-gallery-image__figure__img"
      :style="ugcGalleryImageComputedStyle"
      role="img"
      :aria-label="`Instagram photo from @${ props.username }`"
    />
    <figcaption
      v-if="props.showUgcMetadata"
      class="ugc-gallery-image__figcaption"
    >
      <InstagramLogo
        width="23"
        height="23"
      />
      <span
        class="ugc-gallery-image__figcaption__text"
        data-testid="ugc-gallery-image-figcaption-text"
      >@{{ props.username }}</span>
    </figcaption>
  </figure>
</template>

<style lang="scss" scoped>
.ugc-gallery-image {
  &__figure {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    background-color: $color-black;
    border: 1px solid $color-gray-70;
    border-radius: 3px;
    text-align: center;
    pointer-events: auto;

    &__img {
      width: 266px;
      height: 266px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &__figcaption {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    background-color: $color-white;
    padding: 8px;
    user-select: none;

    svg {
      width: 23px;
    }

    &__text {
      @extend %font-accent;
      box-sizing: border-box;
      color: $color-black;
      text-align: left;
      font-style: normal;
      font-weight: 400;
      font-size: 1.25rem;
      line-height: 1.25;
      -webkit-font-smoothing: antialiased;
      white-space: nowrap;
      overflow: hidden;
      width: 227px; // For handling long usernames
      text-overflow: ellipsis; // For handling long usernames
      padding-left: 8px;
      letter-spacing: 0;
    }
  }
}
</style>
