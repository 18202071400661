/**
 * This function is used to construct the category and subcategory for the analytics:
 * if subcategory exists it concatenates 'category >> subcategory'.
 * otherwise, it checks if 'category' exists and uses it as the page name.
 * if both 'subcategory' and 'category' are undefined, it uses an empty string as the page name
 * @param {string} category    - The category name
 * @param {string} subcategory - The subcategory name
 * @returns {string}           - The constructed category and subcategory string
 * @example
 * const category = 'Automotive';
 * const subcategory = 'Motorcycle-Gps-Navigation';
 * const analyticsCatSubcat = constructAnalyticsCatSubcat(category, subcategory);
 * the result will be:
 * analyticsCatSubcat = 'Automotive'
 * analyticsCatSubcat = 'Automotive >> Motorcycle-Gps-Navigation'
 */
export const constructAnalyticsCatSubcat = (category, subcategory) => (subcategory ? `${category} >> ${subcategory}` : category || '');

export default constructAnalyticsCatSubcat;
