/**
 * @param {Function} callback - The callback function to be called after the debounce time
 * @param {number} wait       - The time to wait before calling the callback
 * @returns {Function}        - The debounced function
 * @example
 * const debouncedFunction = debounce(() => console.log('Hello World'), 500);
 */
export const debounce = (callback, wait) => {
  let timeoutId = null;
  return (...args) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      // eslint-disable-next-line prefer-spread
      callback.apply(null, args);
    }, wait);
  };
};

export default debounce;
