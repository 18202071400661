import request from './request.js';
import config from '../config/index.js';

const olaPicAPIDomain = 'https://photorankapi-a.akamaihd.net';

export default {
  async getStreamMedia(options) {
    try {
      if (!options) {
        throw new Error('No options passed to getMediaStream');
      }
      if (!options.productId) {
        throw new Error('No product id option set for getMediaStream');
      }

      const requestConfig = {
        // eslint-disable-next-line max-len
        url: `${olaPicAPIDomain}/streams/media/recent?tag_key=${options.productId}&version=v2.2&auth_token=${config.apiKey}&rights_given=1&include_tagged_galleries=0`,
      };
      const response = await request(requestConfig);
      const cleanedResponse = response.data._embedded.media
        .filter((post) => post.status === 'approved')
        .map((entry) => ({
          images: entry.images,
          username: entry._embedded.uploader.username,
        }));
      return cleanedResponse;
    } catch (error) {
      // Parsing Error / Network Error / 404 / 500
      throw new Error(`Unable to load data - ${error}`);
    }
  },
  async getCategoryByKey(options) {
    try {
      if (!options) {
        throw new Error('No options passed to getCategoryByKey');
      }
      if (!options.categoryKey) {
        throw new Error('No category key option set for getCategoryByKey');
      }

      const requestConfig = {
        // eslint-disable-next-line max-len
        url: `${olaPicAPIDomain}/customers/${config.customerId}/categories/search?tag_key=${options.categoryKey}&auth_token=${config.apiKey}&version=v2.2`,
      };
      const response = await request(requestConfig);
      return response;
    } catch (error) {
      // Parsing Error / Network Error / 404 / 500
      throw new Error(`Unable to load data - ${error}`);
    }
  },
  async getCategoryMedia(options) {
    try {
      if (!options) {
        throw new Error('No options passed to getCategoryMedia');
      }
      if (!options.mediaUrl) {
        throw new Error('No category media url option set for getCategoryMedia');
      }

      const requestConfig = {
        url: `https:${options.mediaUrl}`,
      };
      const response = await request(requestConfig);
      const cleanedResponse = response.data._embedded.media
        .filter((post) => post.status === 'approved')
        .map((entry) => ({
          images: entry.images,
          username: entry._embedded.uploader.username,
        }));
      return cleanedResponse;
    } catch (error) {
      // Parsing Error / Network Error / 404 / 500
      throw new Error(`Unable to load data - ${error}`);
    }
  },
  async getSettings(options) {
    try {
      const urlOrigin = options.dev ? 'https://olapic-test.fe.garmin.com' : 'https://olapic.fe.garmin.com';
      const requestConfig = {
        url: `${urlOrigin}/api/settings?locale=${options.locale}`,
      };
      const response = await request(requestConfig);
      return response;
    } catch (error) {
      throw new Error(`Unable to load settings - ${error}`);
    }
  },
};
