<script setup>
/* Imports */
import {
  computed,
  reactive,
  ref,
  watch,
  onMounted,
  onUnmounted,
} from 'vue';

/* Helpers */
import { debounce } from '../helpers/debounce';

/* Components */
import GalleryImage from './GalleryImage.vue';
import NavControls from './NavControls.vue';

/* Props */
const props = defineProps({
  displayFormat: {
    type: String,
    default: 'carousel',
  },
  enabled: {
    type: Boolean,
    default: false,
  },
  heading: {
    type: String,
    default: undefined,
  },
  media: {
    type: Array,
    default() {
      return [];
    },
  },
  showUgcMetadata: {
    type: Boolean,
    default: true,
  },
  translations: {
    type: Object,
    default() {
      return {};
    },
  },
});

/* Define emits */
const emit = defineEmits([
  'onPreviousClick',
  'onNextClick',
  'onSwipe',
]);

const galleryWrapper = ref(null);
const galleryList = ref(null);
const galleryWrapperGrid = ref(null);

/* State */
const state = reactive({
  currentPageNumber: 0,
  activeElementIndex: 0,
  minElementsVisible: 3,
  offset: 0,
  widthOfImage: 268, // 266px wide + 1px border on each side
  galleryWrapperWidth: 0,
  galleryListWidth: 0,
  smallViewportInitialOffset: -94,
  mediumViewportInitialOffset: -124,
  dragDistanceHelper: 110,
  startDragPosition: null,
  endDragPosition: null,
  isDragging: false,
  maxImagesToAdd: 5,
});

/* handle computed values */
const displayGallery = computed(() => props.enabled && props.media.length >= 5);
const oneRem = computed(() => {
  let unit = getComputedStyle(document.documentElement).fontSize || '16px';
  unit = unit.replace('px', '');

  return Number(unit);
});
const listSpacingBefore = computed(() => oneRem.value);
const listItemSpacingAfter = computed(() => oneRem.value);
const gallery = computed(() => props.media);
const navLabel = computed(() => props.translations.navLabel || 'Social Slider Navigation');
const nextLabel = computed(() => props.translations.nextButtonLabel || 'Next');
const previousLabel = computed(() => props.translations.previousButtonLabel || 'Previous');
const viewportWidth = computed(() => Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0));
const imageTotalWidth = computed(() => state.widthOfImage + listItemSpacingAfter.value);
const previousBtnDisabled = computed(() => !state.currentPageNumber);
const displayNavControls = computed(() => {
  // If we are on the first image, need to add list left padding into total width
  const offset = state.currentPageNumber === 0 ? (listSpacingBefore.value * 1.5) : 0;
  if ((state.galleryListWidth - offset) <= state.galleryWrapperWidth) {
    return false;
  }
  return true;
});
const widthOf3Images = computed(() => listSpacingBefore.value + (imageTotalWidth.value * 3));
const widthOf4Images = computed(() => listSpacingBefore.value + (imageTotalWidth.value * 4));
const smallViewportCenterOffset = computed(() => (-0.5 * (widthOf3Images.value - viewportWidth.value)));
const mediumViewportCenterOffset = computed(() => (-0.5 * (widthOf4Images.value - viewportWidth.value)));
const scrollPosition = computed(() => {
  if (state.isDragging) {
    if (state.endDragPosition !== null) {
      return state.offset + (state.startDragPosition - state.endDragPosition);
    }
    return state.offset + state.startDragPosition;
  }
  if (state.offset < 0) {
    return 0;
  }
  return state.offset;
});
const getIsDragging = computed(() => state.isDragging);
const getDraggingDisabled = computed(() => displayNavControls.value);
const ugcGalleryListComputedClass = computed(() => ({
  'ugc-gallery__list': true,
  'ugc-gallery__list--dragging': getIsDragging.value,
  'ugc-gallery__list--transition': !getIsDragging.value,
  'ugc-gallery__list--dragging-disabled': !getDraggingDisabled.value,
}));
const ugcGalleryListComputedStyle = computed(() => ({
  transform: `translateX(-${scrollPosition.value}px)`,
}));
const isCarousel = computed(() => props.displayFormat === 'carousel');
const baseClass = computed(() => `ugc-gallery ${isCarousel.value ? '' : 'ugc-gallery--grid'}`);

/* Methods */
const setGalleryDimensions = () => {
  state.galleryWrapperWidth = galleryWrapper?.value?.clientWidth || undefined;
  state.galleryListWidth = galleryList?.value?.clientWidth || undefined;
};

/* Add gallery element */
const addGalleryElement = (amount) => {
  if (amount) {
    for (let i = 0; i < amount; i += 1) {
      gallery.value.push(gallery.value[state.activeElementIndex]);
      state.activeElementIndex += 1;
    }
  } else {
    gallery.value.push(gallery.value[state.activeElementIndex]);
    state.activeElementIndex += 1;
  }
};

/* Next click */
const onNextClick = () => {
  if (state.currentPageNumber === 0) {
    let initialOffset = 0;

    // If the user is on the first card (ie: currentPageNumber === 0)
    // We set an initial offset position that changes depending on screen
    // size. This is needed to improve the layout on mobile screens.
    // Every other click, we add a fixed value (imageTotalWidth) to
    // state.offset except when the first card is active.
    initialOffset = smallViewportCenterOffset.value;
    if (smallViewportCenterOffset.value > state.smallViewportInitialOffset) {
      initialOffset = mediumViewportCenterOffset.value;
    }
    if (mediumViewportCenterOffset.value > state.mediumViewportInitialOffset) {
      initialOffset = imageTotalWidth.value;
    }
    // Apply our initial offset
    state.offset = Math.abs(initialOffset);

    // On the initial next click, add 5 images to the gallery
    // This helps with the user experience if the user starts on a small screen
    // and then makes it wider
    addGalleryElement(state.maxImagesToAdd);
  } else {
    state.offset += imageTotalWidth.value;
    addGalleryElement();
  }

  state.currentPageNumber += 1;
  emit('onNextClick');
};

/* Previous click */
const onPreviousClick = () => {
  // Calculate the offset
  if ((state.offset - imageTotalWidth.value) < 0) {
    state.offset = 0;
  } else {
    state.offset -= imageTotalWidth.value;
  }

  state.currentPageNumber -= 1;
  emit('onPreviousClick');
};

/* Watchers */
watch(() => props.media, (newVal) => {
  if (newVal.length) {
    setTimeout(() => {
      setGalleryDimensions();
    }, 100);
  }
});

/* Prevent overflow while dragging */
const preventOverflow = () => {
  if (state.offset < 0) {
    state.offset = 0;
  }

  // Extra check to prevent scrolling beyond the gallery bounds
  if ((state.offset / imageTotalWidth.value) + 1 > gallery.value.length - state.minElementsVisible) {
    state.offset = (gallery.value.length - state.minElementsVisible) * (imageTotalWidth.value);
  }
};

/* Set drag position */
const setDragPosition = (event, type) => {
  if (event?.changedTouches?.length) {
    if (type === 'start') {
      state.endDragPosition = event?.changedTouches[0]?.pageX;
      state.startDragPosition = event?.changedTouches[0]?.pageX;
    } else if (type === 'drag') {
      for (let i = 0; i < event?.changedTouches?.length; i += 1) {
        state.endDragPosition = event?.changedTouches[i]?.pageX;
      }
    }
  } else if (type === 'start') {
    state.endDragPosition = event?.pageX;
    state.startDragPosition = event?.pageX;
  } else if (type === 'drag') {
    state.endDragPosition = event?.pageX;
  }
};

/* Start drag */
const startDrag = (event) => {
  const previousBtn = 'ugc-gallery__previous';
  const nextBtn = 'ugc-gallery__next';

  if (displayNavControls.value) {
    if (!event.target.id.includes(previousBtn) || !event.target.id.includes(nextBtn)) {
      state.isDragging = true;

      // Duplicate gallery elements if the first image is active
      if (state.currentPageNumber === 0) {
        const galleryLength = gallery.value.length;

        if (galleryLength > state.maxImagesToAdd) {
          addGalleryElement(state.maxImagesToAdd);
        } else {
          addGalleryElement(galleryLength);
        }
      }

      // handle touch events
      setDragPosition(event, 'start');
    }
  }
};

/* Stop drag */
const stopDrag = () => {
  if (displayNavControls.value && state.isDragging) {
    state.isDragging = false;

    let dragDistance = state.startDragPosition - state.endDragPosition;

    // Reduce drag distance required to rotate gallery
    if (dragDistance > 0) {
      dragDistance += state.dragDistanceHelper;
    } else if (dragDistance < 0) {
      dragDistance -= state.dragDistanceHelper;
    }

    const dragDistanceImagesWidth = (Math.round(dragDistance / imageTotalWidth.value) * imageTotalWidth.value);
    const dragDistanceImagesCount = dragDistanceImagesWidth / imageTotalWidth.value;
    const draggedLeft = dragDistance > 0;
    const draggedRight = dragDistance < 0;
    let initialOffset = 0;

    // Return if user clicks without dragging to the next slide
    if (dragDistance === 0 || Math.abs(dragDistanceImagesCount) < 1) return;

    if (state.currentPageNumber < 0) state.currentPageNumber = 0;

    if (state.currentPageNumber === 0) {
      if (draggedRight) return;

      // If the user is on the first card (ie: currentPageNumber === 0)
      // We set an initial offset position that changes depending on screen
      // size. This is needed to improve the layout on mobile screens.
      // All other swipes, we add a fixed value (imageTotalWidth * dragDistanceImagesCount)
      // to state.offset except when the first card is active or if they drag > 1 card's width.
      initialOffset = smallViewportCenterOffset.value;
      if (smallViewportCenterOffset.value > state.smallViewportInitialOffset) {
        initialOffset = mediumViewportCenterOffset.value;
      }
      if (mediumViewportCenterOffset.value > state.mediumViewportInitialOffset) {
        initialOffset = imageTotalWidth.value;
      }
      // Apply our initial offset
      state.offset += Math.abs(initialOffset);

      // Add the dragged distance to the initial
      // offset if they drag > 1 image width
      if (dragDistanceImagesCount > 1) {
        state.offset += (dragDistanceImagesWidth - imageTotalWidth.value);
      }
    } else {
      initialOffset = dragDistanceImagesWidth;
      state.offset += initialOffset;
    }

    if ((state.currentPageNumber + dragDistanceImagesCount) < 0) {
      state.currentPageNumber = 0;
    } else {
      state.currentPageNumber += dragDistanceImagesCount;
    }

    // Add a gallery element for each image dragged
    if (draggedLeft && dragDistanceImagesCount >= 1) {
      addGalleryElement(dragDistanceImagesCount);
    }

    // Send Analytics Event
    if (Math.abs(dragDistanceImagesCount) >= 1) {
      const dragDirection = draggedLeft ? 'Left' : 'Right';
      emit('onSwipe', dragDirection);
    }

    preventOverflow();
  }
};

/* On drag */
const onDrag = (event) => {
  if (!state.isDragging) return;

  state.endDragPosition = event?.pageX;
  setDragPosition(event, 'drag');
};

/* Events */
onMounted(() => {
  setGalleryDimensions();
});

// On resize, set gallery dimensions
const handleResize = debounce(() => {
  setGalleryDimensions();
}, 300);

window.addEventListener('resize', handleResize);

document.addEventListener('mouseleave', () => {
  stopDrag();
});

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
  document.removeEventListener('mouseleave', stopDrag());
});
</script>

<template>
  <section
    v-if="displayGallery"
    :class="baseClass"
    data-testid="ugc-gallery"
  >
    <!-- eslint-disable vue/no-v-html -->
    <h1
      v-if="props.heading"
      data-testid="ugc-gallery-heading"
      class="ugc-gallery__heading"
      v-html="props.heading"
    />
    <!-- eslint-enable vue/no-v-html -->
    <div
      v-if="isCarousel"
      ref="galleryWrapper"
      class="ugc-gallery__wrapper"
      @mousedown.prevent="startDrag($event)"
      @touchstart="startDrag($event)"
      @mousemove="onDrag($event)"
      @touchmove="onDrag($event)"
      @touchend="stopDrag()"
      @mouseup="stopDrag()"
    >
      <ul
        ref="galleryList"
        :class="ugcGalleryListComputedClass"
        :style="ugcGalleryListComputedStyle"
        data-testid="ugc-gallery-list"
      >
        <li
          v-for="(item, index) in props.media"
          :key="index"
          class="ugc-gallery__list__item"
        >
          <GalleryImage
            :images="item.images"
            :show-ugc-metadata="props.showUgcMetadata"
            :username="item.username"
          />
        </li>
      </ul>
      <NavControls
        v-if="displayNavControls"
        :nav-label="navLabel"
        :previous-btn-label="previousLabel"
        :previous-btn-disabled="previousBtnDisabled"
        :next-btn-label="nextLabel"
        @on-previous-click="onPreviousClick"
        @on-next-click="onNextClick"
      />
    </div>
    <div
      v-else
      ref="galleryWrapperGrid"
      class="ugc-gallery__wrapper--grid"
    >
      <GalleryImage
        v-for="(item, index) in props.media"
        :key="index"
        class="ugc-gallery-image"
        :images="item.images"
        :show-ugc-metadata="props.showUgcMetadata"
        :username="item.username"
      />
    </div>
  </section>
</template>

<style lang="scss" scoped>
$gallery-padding: 1.5rem;
$gallery-mobile-padding: 1rem;

.ugc-gallery {
  position: relative;
  display: inline-block;
  margin-bottom: 3rem;
  width: 100%;

  &--grid {
    display: block;
  }

  &__heading {
    @extend %font-accent;
    display: block;
    margin: 0;
    padding: 2rem;
    font-size: 1.5rem;
    color: $color-black;
    text-align: center;
    text-transform: uppercase;
    line-height: normal;
  }

  &__wrapper {
    position: relative;
    overflow: hidden;
    // Fallback to 100vw if now variable is used
    max-width: var(--g-gallery-max-width, 100vw);

    &--grid {
      box-sizing: border-box;
      margin: 0 auto;
      max-width: 1452px;
      padding: 0 $gallery-mobile-padding;
      text-align: center;

      @include breakpoint('md') {
        padding: 0 $gallery-padding;
      }

      .ugc-gallery-image {
        display: inline-block;
        margin: 0 0 1rem 0;

        @include breakpoint ('sm') {
          margin-right: 1rem;
        }

        @include breakpoint('xxl') {
          &:nth-of-type(5n) {
            margin-right: 0;
          }
        }
      }
    }
  }

  &__list {
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
    padding: 0 $gallery-mobile-padding;
    position: relative;
    transition: none;
    z-index: 10;
    cursor: grab;

    @include breakpoint(md) {
      padding: 0 $gallery-padding;
    }

    &--dragging {
      cursor: grabbing;
    }

    &--transition {
      transition: 0.5s transform cubic-bezier(0.23, 1, 0.32, 1);
    }

    &--dragging-disabled {
      cursor: auto;
    }

    &__item {
      margin: 0;
      padding: 0;
      list-style: none;

      & + & {
        margin-left: 1rem;
      }
    }
  }
}
</style>
