/**
 * A wrapper helper to perform HTTP requests using Axios.
 *
 * Usage:
 * ```javascript
 * request({
 *   url: <THE_API_URL>,                // This is required and will fail if not provided
 *   method: 'GET' | 'POST' | 'PUT',    // Default is GET
 *   payload: { ... },                  // Payload for POST/PUT requests
 *   headers: { ... }                   // Optional custom headers
 * });
 * ```
 *
 * The function returns the output of the request without requiring explicit Promise handling.
 */

/**
 * Creates a global config object with default values for HTTP requests.
 * @param {object} [config] - Optional configuration overrides.
 * @param {string} config.url - The URL for the request (required).
 * @param {string} [config.method] - The HTTP method to use (default is GET).
 * @param {object} [config.payload] - The payload object for POST/PUT requests.
 * @param {object} [config.headers] - Custom headers to set for the request.
 * @throws {Error} If the URL is not provided.
 * @returns {object} - Merged configuration object with default values.
 */
const makeConfig = (config) => {
  const defaults = {
    url: '',
    params: undefined,
    method: 'GET',
    payload: undefined,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  if (!config.url) throw new Error('url is required to make request');

  return {
    ...defaults,
    ...config,
  };
};

/**
 * Executes an HTTP request using Axios with the specified configuration.
 * @param {object} config                - Configuration for the HTTP request.
 * @param {string} config.url            - Required URL to request.
 * @param {object} [config.params]       - Query parameters for the request.
 * @param {string} [config.method]       - The request method (default is GET).
 * @param {object} [config.payload]      - The payload object for POST/PUT requests.
 * @param {object} [config.headers]      - Custom headers for the request.
 * @returns {Promise<object>}            - A promise that resolves to the response data from the HTTP request.
 * @throws {Error} If the request fails, an error is thrown with the response message.
 */
export const request = async (config = {}) => {
  const mergedConfig = makeConfig(config);
  const {
    method,
    url,
    params,
    headers,
    payload,
  } = mergedConfig;

  const queryString = new URLSearchParams(params).toString();
  const fetchUrl = params ? `${url}?${queryString}` : url;

  const response = await fetch(fetchUrl, {
    method,
    headers,
    body: JSON.stringify(payload),
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || 'Something went wrong');
  }

  return data;
};

export default request;
